export const Endpoints = {
  login: `/api/v2/auth/store-sign-in`,
  signUp: `/api/v3/affiliate-users`,
  forgotPasswordSendEmail: `/api/v3/auth/password/forget_request`,
  forgotPasswordCheckOtp: `/api/v3/auth/password/confirm_otp`,
  forgotPasswordResetPassword: `/api/v3/auth/password/confirm_reset`,

  getShortBook: `/api/v2/books/shorts`,
  affiliateCheck: `/api/v2/user`,

  // Bank Info
  addBankDetails: `/api/v3/affiliate/bank-details`,

  affiliateChannels: `/api/v3/affiliate/channels`,

  // Admin App Readers
  getCoverImagesForBooks: `api/v2/books/cover-file`,
  getAllAuthors: `/api/v2/authors`,
  getAllProducts: `/api/v3/products/index_search`,
  createBook: `/api/v2/books`,
  getAllCategories: `/api/v2/categories`,
  getAllChapters: `/api/v2/chapters`,
  createAuthor: `/api/v2/authors`,
  getAuthorAvatar: `/api/v2/authors/avatar`,
  getProductsList: `/api/v2/books`,

  appWriteUser: `/api/v3/auth/appwrite-user`,

  // Banner
  getAllBanner: `/api/v2/banner`,

  updateBannerOrder: `/api/v2/banner/update-banner-order`,

  // Pages
  getAllPages: `/api/v2/banner-page`,

  // Landing Page
  getAllLandingPage: `/api/v2/landing-page`,

  uploadImage: `/api/v2/landing-page/upload-image`,

  createLandingPage: `/api/v2/landing-page`,

  updateLandingPageStatus: `/api/v2/landing-page`,

  getAllProductReviews: `api/v2/review`,

  // Personality Test
  getAllCategory: `api/v2/personality-test/category`,
  resultsPage: `api/v2/personality-test/results`,
  questions: "api/v2/personality-test/question",
  options: "api/v2/personality-test/option",
  deleteOption: "api/v2/personality-test/option",
  getUserSubmission: "api/v2/personality-test/user-submission/users",
  onboardingSubmission: "api/v2/onboarding-user-submission",
};
