import React from "react";

import { Helmet } from "react-helmet";
import { pageTitle } from "../../components/shared/basic/customHead";
import CategoryTable, {
  CategoryType,
} from "../../components/PersonalityTypeComp/CategoryTable";

interface Props {
  type?: CategoryType;
}

const PersonalityTypeTestPage: React.FC<Props> = ({ type }) => {
  return (
    <div>
      <Helmet>
        <title>{pageTitle("Personality Type")}</title>
      </Helmet>
      <CategoryTable type={type} />
    </div>
  );
};

export default PersonalityTypeTestPage;
