import React from "react";
import "./styles/global.scss";
import { Route, Routes, HashRouter } from "react-router-dom";
import Login from "./components/Auth/Login";
import DefaultLayout from "./components/Layout";
import { ConfigProvider, ThemeConfig, theme as antdTheme } from "antd";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import ProductsPage from "./pages/ProductsPage";
import ChapterPage from "./pages/ProductsPage/ChapterPage";
import AuthorsPage from "./pages/Authors";
import CategoriesPage from "./pages/Categories";
import BannerPage from "./pages/BannerPages";
import Banners from "./pages/Banners";
import BookLandingPage from "./pages/BookLandingPage";
import { RecoilRoot } from "recoil";
import BookLandingPageForm from "./components/BookLandingPageComp/CreateLandingPage";
import ReviewsPage from "./pages/Reviews";

// Define a type for your route paths
type RoutePath =
  | "/"
  | "/signUp"
  | "/dashboard"
  | "/profile-settings"
  | "/products"
  | "/dashboard/:id"
  | "/dashboard/paymentHistory/:id"
  | "/profile-settings/:id"
  | "/products/:bookId"
  | "/authors"
  | "/categories"
  | "/reset-password"
  | "/bannerPages"
  | "/bannerPages/:id"
  | "/book-landing-page"
  | "/book-landing-page/:landingPageId"
  | "/book-landing-page/create"
  | "/reviews/:productId";

// Define a type for the elements that can be rendered within the routes
type RouteElement = React.ReactElement;

// Define an array of route paths where DefaultLayout should not be used
const routesWithoutDefaultLayout: RoutePath[] = [
  "/",
  "/signUp",
  "/reset-password",
];

// Custom function to determine whether to use DefaultLayout
function renderRoute(path: RoutePath, element: RouteElement): RouteElement {
  return routesWithoutDefaultLayout.includes(path) ? (
    element
  ) : (
    <DefaultLayout>{element}</DefaultLayout>
  );
}

const config: ThemeConfig = {
  token: {
    colorPrimary: "#FF5612",
  },
};

function App() {
  // const triggerToggleTheme = useRecoilValue(toggleTheme);
  return (
    <div className="App ">
      <HashRouter>
        <RecoilRoot>
          <ConfigProvider
            theme={{
              ...config,
              algorithm: [antdTheme.darkAlgorithm],
            }}
          >
            <Routes>
              <Route path="/" element={renderRoute("/", <Login />)} />

              <Route
                path="/products"
                element={renderRoute("/products", <ProductsPage />)}
              />
              <Route
                path="/products/:bookId"
                element={renderRoute("/products/:bookId", <ChapterPage />)}
              />
              <Route
                path="/authors"
                element={renderRoute("/authors", <AuthorsPage />)}
              />
              <Route
                path="/categories"
                element={renderRoute("/categories", <CategoriesPage />)}
              />
              <Route
                path="/bannerPages"
                element={renderRoute("/bannerPages", <BannerPage />)}
              />
              <Route
                path="/bannerPages/:id"
                element={renderRoute("/bannerPages/:id", <Banners />)}
              />
              <Route
                path="/book-landing-page"
                element={renderRoute("/book-landing-page", <BookLandingPage />)}
              />
              <Route
                path="/reviews/:productId"
                element={renderRoute("/reviews/:productId", <ReviewsPage />)}
              />
              <Route
                path="/book-landing-page/create"
                element={renderRoute(
                  "/book-landing-page/create",
                  <BookLandingPageForm />
                )}
              />

              <Route
                path="/book-landing-page/:landingPageId"
                element={renderRoute(
                  "/book-landing-page/:landingPageId",
                  <BookLandingPageForm />
                )}
              />
            </Routes>
          </ConfigProvider>
        </RecoilRoot>
      </HashRouter>
      <ToastContainer autoClose={2000} theme={"dark"} />
    </div>
  );
}

export default App;
