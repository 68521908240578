import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Space,
  Popconfirm,
  Empty,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { formatErrorObject } from "../../../utils/lib/auth";
import {
  createResultsAPI,
  deleteResultsAPI,
  getResultsAPI,
  updateCategoryAPI,
} from "../../../services/personalityTest";
import { notify } from "../../shared/basic/notify";
import { PlusOutlined } from "@ant-design/icons";
import { ShouldRender } from "../../shared/basic/ShouldRender";
import ButtonDefault, { ButtonVariants } from "../../shared/basic/button";
import { ChevronLeftIcon } from "@heroicons/react/outline";
import { useNavigate } from "react-router-dom";

export interface IResults {
  _id: string;
  title: string;
  content: string;
}

const ResultsPage: React.FC = () => {
  const [results, setResults] = useState<IResults[]>([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingResults, setEditingResults] = useState<IResults | null>(null);
  const [editorLoaded, setEditorLoaded] = useState(false);

  const [form] = Form.useForm();
  const router = useNavigate();
  const getResults = async () => {
    try {
      const data = await getResultsAPI();
      setResults(data?.data?.results);
    } catch (error) {
      notify(formatErrorObject(error), "error");
    }
  };

  const editorRef = useRef<any>();
  const { CKEditor, ClassicEditor } = editorRef.current || {};

  useEffect(() => {
    editorRef.current = {
      CKEditor: require("@ckeditor/ckeditor5-react"),
      ClassicEditor: require("@ckeditor/ckeditor5-build-classic"),
    };
    setEditorLoaded(true);
  }, []);

  const handleCreateResults = async (data: Partial<IResults>) => {
    try {
      await createResultsAPI(data);
      notify("Result page created successfully", "success");
      closeModal();
      getResults();
    } catch (error) {
      notify(formatErrorObject(error), "error");
    }
  };

  const handleUpdateResults = async (id: string, data: Partial<IResults>) => {
    try {
      await updateCategoryAPI(id, data);
      notify("ResultsPage updated successfully", "success");
      getResults();
      closeModal();
    } catch (error) {
      notify(formatErrorObject(error), "error");
    }
  };

  const handleDeleteResultPage = async (id: string) => {
    try {
      await deleteResultsAPI(id);
      notify("Result Page deleted successfully", "success");
      getResults();
    } catch (error) {
      notify(formatErrorObject(error), "error");
    }
  };

  const openModal = (category?: IResults) => {
    setIsModalOpen(true);
    setEditingResults(category || null);
    form.setFieldsValue(category || { name: "", valueTypes: [] });
  };

  const closeModal = () => {
    setIsModalOpen(false);
    form.resetFields();
    setEditingResults(null);
  };

  const handleModalSubmit = async () => {
    try {
      const values = await form.validateFields();

      if (editingResults) {
        await handleUpdateResults(editingResults._id, values);
      } else {
        await handleCreateResults(values);
      }
    } catch (error) {
      notify(formatErrorObject(error), "error");
      throw "";
    }
  };

  const preprocessHTML = (html: string): string => {
    // Create a temporary DOM element to manipulate the HTML
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;

    // Remove empty paragraphs
    const paragraphs = tempDiv.querySelectorAll("p");
    paragraphs.forEach((p) => {
      if (p?.textContent && !p?.textContent.trim()) {
        p.remove();
      }
    });

    // Return the cleaned HTML
    return tempDiv.innerHTML;
  };

  const columns: ColumnsType<IResults> = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },

    {
      title: "Actions",
      key: "actions",
      render: (_, record) => {
        return (
          <Space>
            <Button type="link" onClick={() => openModal(record)}>
              Edit
            </Button>

            <Popconfirm
              title="Delete this result page?"
              onConfirm={() => handleDeleteResultPage(record._id)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="text" danger>
                Delete
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    getResults().finally(() => setLoading(false));
  }, []);

  const rowClassName = (_: any, index: number) => {
    return index % 2 === 0 ? "bg-[#131313]" : "bg-bgColor";
  };
  return (
    <div className="bg-readerLightGrayBg rounded-xl ">
      <div className="py-4 px-6 flex flex-col justify-between md:flex-row gap-x-2 items-center">
        <p className="font-semibold items-center gap-x-2 flex justify-start text-start text-base lg:text-lg whitespace-nowrap">
          <Button
            onClick={() => router("/personality-type")}
            icon={<ChevronLeftIcon className="w-3 " />}
            type="text"
          >
            Back
          </Button>{" "}
          All Results Page{" "}
        </p>

        <p className="font-semibold flex justify-start text-start text-base lg:text-lg whitespace-nowrap"></p>
        <span className="flex gap-x-4">
          <Button onClick={() => openModal()} icon={<PlusOutlined />}>
            Create Results Page
          </Button>
        </span>
      </div>
      <div className="customScrollBar">
        <Table
          columns={columns}
          dataSource={results}
          rowKey="_id"
          className={`tableHeader  rounded-none customTable overflow-auto customScrollBar`}
          loading={loading}
          rowClassName={rowClassName}
          bordered
          locale={{
            emptyText: (
              <span>
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={<span>No Result Pages</span>}
                  className={`h-40 flex justify-center items-center flex-col`}
                />
              </span>
            ),
          }}
          pagination={false}
        />
      </div>

      <Modal
        title={editingResults ? "Edit Results" : "Add Results"}
        open={isModalOpen}
        width={"60%"}
        centered
        onCancel={closeModal}
        footer={
          <div className="flex gap-x-5">
            {/* Cancel Button */}
            <ButtonDefault
              size={4}
              rounded={true}
              className="w-1/3"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                closeModal();
              }}
              variant={ButtonVariants.GRAY}
            >
              <span className="flex text-sm gap-x-2 text-[#4B4B4B] px-4 whitespace-nowrap">
                Cancel
              </span>
            </ButtonDefault>

            {/* Submit Button */}
            <ButtonDefault
              size={4}
              loading={loading}
              rounded={true}
              className="w-2/3"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();

                handleModalSubmit();
              }}
              variant={ButtonVariants.WHITE}
            >
              <span className="flex text-sm gap-x-2 px-4 whitespace-nowrap">
                Submit
              </span>
            </ButtonDefault>
          </div>
        }
        destroyOnClose
      >
        <div>
          <Form form={form} layout="vertical">
            <Form.Item
              label="Title"
              name="title"
              rules={[{ required: true, message: "Please enter the title" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Description"
              name="content"
              rules={[{ required: true, message: "Please enter the content" }]}
            >
              <ShouldRender check={editorLoaded}>
                <CKEditor
                  className="customCkeditorSchool"
                  onChange={(_: any, editor: any) => {
                    const data = editor.getData();
                    form.setFieldValue("content", data || "");
                  }}
                  config={{
                    toolbar: {
                      items: [
                        "heading",
                        "|",
                        "bold",
                        "italic",
                        "link",
                        "bulletedList",
                        "numberedList",
                        "blockQuote",
                        "insertTable",
                        "mediaEmbed",
                        "undo",
                        "redo",
                      ],
                    },
                    alignment: {
                      options: ["left", "center", "right", "justify"],
                    },
                    link: {
                      defaultTarget: "_blank",
                      decorators: {
                        openInNewTab: {
                          mode: "manual",
                          label: "Open in a new tab",
                          defaultValue: true,
                          attributes: {
                            target: "_blank",
                            rel: "noopener noreferrer",
                          },
                        },
                      },
                    },
                  }}
                  data={preprocessHTML(form.getFieldValue("content") || "")}
                  editor={ClassicEditor}
                />
              </ShouldRender>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default ResultsPage;
