import React, { useEffect, useState } from "react";
import {
  Table,
  Avatar,
  Button,
  Rate,
  Spin,
  Modal,
  ConfigProvider,
  ThemeConfig,
  theme,
  Dropdown,
  MenuProps,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { getAllReviews, handleDeleteReview } from "../../services/Review";
import { notify } from "../shared/basic/notify";
import { formateErrorObject } from "../shared/formateErrorObject";
import { useParams } from "react-router-dom";
import { getUserInitials } from "../../pages/Authors";
import ParserV2 from "../shared/Parser";
import EditReviewModal from "./EditReviewModal";
import moment from "moment";
import {
  ChevronDownIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/outline";
import SortIcon from "../../assets/Icons/SortIcon";

export interface IReviewsData {
  user: {
    zl_uid: string;
    name: string;
    avatar: string;
  };
  rating: number;
  title: string;
  text: string;
  createdAt: string;
  images: {
    imageId: string;
    imageURL: string;
  }[];
  _id: string;
}

const config: ThemeConfig = {
  token: { colorPrimary: "#FFFFFFB2", fontFamily: "inherit" },
};

const Review: React.FC = () => {
  const [data, setData] = useState<IReviewsData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedSort, setSortSelected] = useState("top_review");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 15,
    total: 0,
  });
  const [deleteReview, setDeleteReview] = useState<{
    value: boolean;
    reviewId: string | undefined;
  }>({
    reviewId: undefined,
    value: false,
  });
  const { productId } = useParams();
  const [editReviewModal, setEditReviewModal] = useState<{
    value: IReviewsData | undefined;
    modalState: boolean;
  }>({ value: undefined, modalState: false });

  const fetchData = async (pageNum = pagination.current) => {
    try {
      setLoading(true);
      const limit = pagination.pageSize;
      const sortParams =
        selectedSort === "most_recent"
          ? { sortByDate: "desc" }
          : { sortByRating: "desc" };

      const response = await getAllReviews({
        page: pageNum,
        limit,
        product_id: productId as string,
        ...sortParams,
      });

      const formattedData = response?.reviews.map((review: IReviewsData) => ({
        ...review,
        user: { ...review.user },
      }));

      setPagination((prev) => ({
        ...prev,
        current: response.meta.currentPage,
        total: response.meta.totalPages * limit,
      }));

      setData(formattedData);
    } catch (error: any) {
      notify(formateErrorObject(error), "error");
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (pagination: any) => {
    setPagination((prev) => ({ ...prev, current: pagination.current }));
    fetchData(pagination);
  };

  useEffect(() => {
    fetchData(1);
  }, [selectedSort]);

  const columns: ColumnsType<IReviewsData> = [
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      render: (user) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar size={24} alt="profile-picture" style={{ fontSize: "14px" }}>
            {user?.name && getUserInitials(user.name)}
          </Avatar>
          <span style={{ marginLeft: 8 }}>{user.name}</span>
        </div>
      ),
    },
    {
      title: "Review Text",
      dataIndex: "text",
      key: "text",
      render: (text) => <ParserV2 text={text} maxWords={10} />,
    },
    {
      title: "Rating",
      dataIndex: "rating",
      key: "rating",
      render: (rating) => (
        <Rate
          disabled
          style={{ fontSize: "22px" }}
          className="text-[#00A86B] "
          defaultValue={rating}
          allowHalf
        />
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => moment(createdAt).format("Do MMM YYYY"),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div className="flex gap-x-2">
          <Button
            onClick={() =>
              setEditReviewModal({
                value: record,
                modalState: true,
              })
            }
          >
            Edit
          </Button>
          <Button
            danger
            onClick={() =>
              setDeleteReview({
                reviewId: record._id,
                value: true,
              })
            }
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  const handleDelete = async (reviewId: string) => {
    await handleDeleteReview(reviewId);
    if (window !== undefined) {
      window.location.reload();
    }
    setDeleteReview({
      reviewId: undefined,
      value: false,
    });
  };

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    setSortSelected(e.key);
  };

  const items: MenuProps["items"] = [
    {
      key: "top_review",
      label: (
        <span
          style={{
            color: selectedSort === "top_review" ? "white" : "inherit",
          }}
        >
          Top review
        </span>
      ),
      onClick: handleMenuClick,
    },
    {
      key: "most_recent",
      label: (
        <span
          style={{
            color: selectedSort === "most_recent" ? "white" : "inherit",
          }}
          className="pt-1"
        >
          Most recent
        </span>
      ),
      onClick: handleMenuClick,
    },
  ];

  return (
    <div>
      <Spin className="relative" spinning={loading}>
        <Table
          columns={columns}
          dataSource={data}
          rowKey={(record) => record._id}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
            onChange: handleTableChange,
          }}
        />
        <div className="absolute -top-11 right-0">
          <ConfigProvider
            theme={{ ...config, algorithm: [theme.darkAlgorithm] }}
          >
            <Dropdown
              menu={{
                items,
                selectedKeys: [selectedSort],
              }}
              trigger={["click"]}
              className="z-50"
            >
              <Button className="flex items-center border-none gap-x-2 bg-[#131313] text-white px-4 py-3 h-9 rounded-md">
                <SortIcon />
                <span className="text-sm">
                  {selectedSort === "most_recent"
                    ? "Most recent"
                    : "Top review"}
                </span>
                <ChevronDownIcon className="w-4 ml-1" />
              </Button>
            </Dropdown>
          </ConfigProvider>
        </div>
      </Spin>

      <EditReviewModal
        handleClose={() =>
          setEditReviewModal({
            value: undefined,
            modalState: false,
          })
        }
        isModalOpen={editReviewModal.modalState}
        fetchData={() => fetchData(1)}
        review={editReviewModal.value}
      />

      <Modal
        open={deleteReview.value}
        maskClosable={false}
        closable={false}
        footer={null}
        onCancel={() =>
          setDeleteReview({
            reviewId: undefined,
            value: false,
          })
        }
      >
        <div className="flex flex-col gap-3 w-full">
          <span className="text-center flex justify-center">
            <ExclamationCircleIcon className="text-yellow-500 w-12 h-12" />
          </span>
          <div className="flex justify-center w-full text-center">
            <div className=" justify-center flex flex-col w-full text-lg pb-4">
              <span>Are you sure? You want to delete this review ?</span>{" "}
              <span>Action once completed can not be changed</span>
            </div>
          </div>

          <span className="flex justify-center gap-x-2">
            <Button
              onClick={() =>
                setDeleteReview({
                  reviewId: undefined,
                  value: false,
                })
              }
            >
              No
            </Button>
            <Button
              danger
              type="primary"
              onClick={() => {
                handleDelete(deleteReview.reviewId as string);
              }}
            >
              {`Yes, I'm sure`}
            </Button>
          </span>
        </div>
      </Modal>
    </div>
  );
};

export default Review;
