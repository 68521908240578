import { Input, Modal } from "antd";
import React, { useEffect, useState } from "react";

import ButtonDefault, { ButtonVariants } from "../../shared/basic/button";
import { notify } from "../../shared/basic/notify";
import { formateErrorObject } from "../../shared/formateErrorObject";
import {
  createChapter,
  editChapter,
  getSingleChapter,
} from "../../../services/dashboard";

import extractErrorMessage from "../../shared/basic/formateError";
import UploadFile from "../../ProductsComp/UploadFile";
import { useParams } from "react-router-dom";

interface ModalComponentProps {
  visible: boolean;
  onCancel: () => void;
  isClosable?: boolean;
  type?: string;
  chapterId?: number;
}
export const detectSilences = async (audioFile: File): Promise<number[]> => {
  const chunkSize = 30 * 1024 * 1024;
  const audioContext = new (window.AudioContext ||
    (window as any).webkitAudioContext)();
  const silences: number[] = [];
  let ongoingSilenceStart: number | null = null;

  const processChunk = (arrayBuffer: ArrayBuffer, chunkOffset: number) => {
    return new Promise<number[]>((resolve) => {
      audioContext.decodeAudioData(arrayBuffer, (audioBuffer) => {
        const data = audioBuffer.getChannelData(0);
        const sampleRate = audioBuffer.sampleRate;

        const silenceThreshold = 0.02;
        const minSilenceDuration = 3;

        let silenceStart: number | null = ongoingSilenceStart;
        const chunkSilences: number[] = [];

        for (let i = 0; i < data.length; i++) {
          const time = chunkOffset + i / sampleRate;

          if (Math.abs(data[i] as number) < silenceThreshold) {
            if (silenceStart === null) silenceStart = time;
          } else {
            if (silenceStart !== null) {
              const silenceDuration = time - silenceStart;

              if (silenceDuration >= minSilenceDuration) {
                chunkSilences.push(silenceStart);
              }
            }
            silenceStart = null;
          }
        }

        ongoingSilenceStart = silenceStart;
        resolve(chunkSilences);
      });
    });
  };

  let offset = 0;
  const fileReader = new FileReader();

  const readNextChunk = (resolve: (value: number[]) => void) => {
    if (offset >= audioFile.size) {
      if (ongoingSilenceStart !== null) {
        silences.push(ongoingSilenceStart);
      }
      resolve(silences);
      return;
    }

    const blob = audioFile.slice(offset, offset + chunkSize);
    fileReader.onload = async (event) => {
      if (!event.target?.result) return;
      const chunkSilences = await processChunk(
        event.target.result as ArrayBuffer,
        offset / chunkSize
      );
      silences.push(...chunkSilences);
      offset += chunkSize;
      readNextChunk(resolve);
    };

    fileReader.readAsArrayBuffer(blob);
  };

  return new Promise((resolve) => {
    readNextChunk(resolve);
  });
};

export const formatTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes} min ${remainingSeconds} sec`;
};

export const mapTimestampsToPages = async (
  silences: number[],
  totalDuration: number
) => {
  if (totalDuration <= 0) return [];
  const timestamps: { page: number; start: string; end: string }[] = [];
  const pageDurations = [0, ...silences, totalDuration];
  console.log({ silences, pageDurations });
  for (let i = 0; i < pageDurations.length - 1; i++) {
    timestamps.push({
      page: i + 1,
      start: formatTime(pageDurations[i] as number),
      end: formatTime(pageDurations[i + 1] as number),
    });
  }

  return timestamps;
};
const ChapterModal: React.FC<ModalComponentProps> = ({
  visible,
  onCancel,
  type,
  chapterId,
}) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [audioLoading, setAudioLoading] = useState(false);

  const [formData, setFormData] = useState<{
    name: string;
    chapterNumber: number | undefined;
    totalPages: number | undefined;
    startPage: number | undefined;
    endPage: number | undefined;
    description: string;
    chapterFile: any;
    timestamps?: { page: number; start: string; end: string }[];
    audioFile?: any;
    audioBookStartingPage?: number | undefined;
  }>({
    name: "",
    chapterNumber: undefined,
    totalPages: undefined,
    startPage: undefined,
    endPage: undefined,
    description: "",
    chapterFile: null,
    audioFile: null,
    timestamps: [],
    audioBookStartingPage: undefined,
  });

  const [errors, setErrors] = useState({
    name: "",
    chapterNumber: "",
    totalPages: "",
    startPage: "",
    endPage: "",
    description: "",
    chapterFile: "",
  });
  const params = useParams();

  const handleCancel = () => {
    onCancel();
    setErrors({
      name: "",
      chapterNumber: "",
      totalPages: "",
      startPage: "",
      endPage: "",
      description: "",
      chapterFile: "",
    });
    setFormData({
      name: "",
      chapterNumber: undefined,
      totalPages: undefined,
      startPage: undefined,
      endPage: undefined,
      description: "",
      chapterFile: undefined,
    });
  };
  const handleOk = () => {
    setConfirmLoading(true);

    setTimeout(() => {
      setConfirmLoading(false);
      handleCancel();
    }, 2000);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const validationErrors = {
      name: "",
      chapterNumber: "",
      totalPages: "",
      startPage: "",
      endPage: "",
      description: "",
      chapterFile: "",
    };
    if (!formData.name) {
      validationErrors.name = "Please Enter Chapter Name";
    }
    if (!formData.chapterNumber?.toString()) {
      validationErrors.chapterNumber = "Please Enter Chapter number";
    }
    if (!formData.totalPages) {
      validationErrors.totalPages = "Please Enter total pages";
    }
    if (!formData.startPage) {
      validationErrors.startPage = "Please Enter start page";
    }
    if (!formData.endPage) {
      validationErrors.endPage = "Please Enter end page";
    }
    if (!formData.description) {
      validationErrors.description = "Please Enter chapter  description";
    }
    if (!formData.chapterFile && type !== "Edit") {
      validationErrors.chapterFile = "Please enter chapter File (PDF)";
    }

    setErrors(validationErrors);

    if (Object.values(validationErrors).every((error) => !error)) {
      try {
        setConfirmLoading(true);
        if (type !== "Edit") {
          await createChapter({
            ...formData,
            bookId: params?.bookId as string,
          });
        } else {
          await editChapter(chapterId?.toString() as string, {
            ...formData,
            bookId: params?.bookId as string,
          });
        }

        notify("Chapter Created Successfully", "success");
        handleCancel();
      } catch (error: any) {
        const message = formateErrorObject(error);
        notify(message, "error");
      } finally {
        setConfirmLoading(false);
      }
    }
  };

  // const handleAllProducts = async (type: string) => {
  //   const isLong = type === "long";
  //   try {
  //     const products = await getAllProducts({
  //       book_type: isLong ? "hardcover" : "digital",
  //       category_id: 6,
  //     });

  //     setProducts(
  //       products?.data?.map((product: any) => ({
  //         productId: isLong ? product?.digital_pair?.id : product?.id,
  //         hardcoverProductId: isLong ? product?.id : product?.id,
  //         digitalProductId: isLong ? product?.digital_pair?.id : product?.id,
  //         slug: product?.slug,
  //         name: product?.name,
  //       }))
  //     );
  //   } catch (error) {
  //     console.error("API call error:", error);
  //   }
  // };
  // useEffect(() => {
  //   if (formData.type && formData.type !== "") {
  //     handleAllProducts(formData.type);
  //   }
  // }, [formData.type]);

  const handleInputChange = (
    name: string,
    value:
      | string
      | File
      | undefined
      | null
      | []
      | { page: number; start: string; end: string }[]
  ) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleAudioFile = async (file: File | null) => {
    if (!file) {
      handleInputChange("audioFile", null);
      handleInputChange("timestamps", []);
      return;
    }
    setAudioLoading(true);

    handleInputChange("audioFile", file);

    const audio = new Audio(URL.createObjectURL(file));
    audio.onloadedmetadata = async () => {
      const totalDuration = audio.duration;
      const silences = await detectSilences(file);
      const generatedTimestamps = await mapTimestampsToPages(
        silences,
        totalDuration
      );

      handleInputChange("timestamps", generatedTimestamps);
      setAudioLoading(false);
    };
  };

  const handleNumberInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value, name } = e.target;
    const inputValue = value;
    const cleanedValue = inputValue?.replace(/\D/g, "");
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: +cleanedValue,
    }));
  };

  const handleSingleBook = async (chapterId: string) => {
    try {
      //   setLoading(true);
      const response = await getSingleChapter(chapterId);
      const data = response?.data;

      setFormData({
        ...formData,
        chapterNumber: data?.chapterNumber,
        description: data?.description,
        endPage: data?.endPage,
        name: data?.name,
        startPage: data?.startPage,
        totalPages: data?.totalPages,
      });

      // response?.meta?.current_page && setPage(response?.meta?.current_page);
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      //   setLoading(false);
    }
  };

  useEffect(() => {
    if (chapterId) {
      handleSingleBook(chapterId.toString());
    }
  }, [chapterId]);

  return (
    <Modal
      title=""
      open={visible}
      centered={true}
      onOk={handleOk}
      closable={false}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      footer={false}
      className="customLoginModal bg-bgColor bg-opacity-70  pt-5  relative rounded-2xl border-2 border-borderDarkGray backdrop-filter backdrop-blur-md "
    >
      <div className="flex items-center flex-col justify-center px-5 md:px-10">
        <div className={` z-30  flex flex-col gap-y-1 w-full `}>
          <div className="pb-3 flex flex-col gap-y-2 ">
            <h1 className="text-xl flex justify-center gap-x-2 font-medium lg:font-normal lg:text-2xl mt-1 leading-10 text-fadedWhite text-center w-full">
              {type} Chapter
            </h1>
          </div>

          <form className="flex flex-col mt-2 gap-y-3 w-full ">
            <div className="col-span-1 flex-col flex gap-y-.5">
              <Input
                type="text"
                name="name"
                autoComplete="off"
                style={{
                  backgroundColor: "#080808",
                }}
                value={formData.name}
                placeholder="Chapter Name"
                onChange={handleChange}
                size="large"
                className="border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-3  text-white bg-bgColor customInputBlack makeBgBlack"
              />
              {errors.name && (
                <p className="text-red-500 text-xs">{errors.name}</p>
              )}
            </div>
            <div className="col-span-1 flex-col flex gap-y-.5">
              <Input
                type="text"
                name="chapterNumber"
                autoComplete="off"
                style={{
                  backgroundColor: "#080808",
                }}
                value={formData.chapterNumber}
                placeholder="Chapter Number"
                onChange={handleNumberInput}
                size="large"
                className="border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-3  text-white bg-bgColor customInputBlack makeBgBlack"
              />
              {errors.chapterNumber && (
                <p className="text-red-500 text-xs">{errors.chapterNumber}</p>
              )}
            </div>
            <div className="flex max-w-full gap-x-2">
              <div className="col-span-1 flex-col flex gap-y-.5 w-1/2">
                <Input
                  type="text"
                  name="startPage"
                  autoComplete="off"
                  style={{
                    backgroundColor: "#080808",
                  }}
                  value={formData.startPage}
                  placeholder="Start page"
                  onChange={handleNumberInput}
                  size="large"
                  className="border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-3  text-white bg-bgColor customInputBlack makeBgBlack"
                />
                {errors.startPage && (
                  <p className="text-red-500 text-xs">{errors.startPage}</p>
                )}
              </div>
              <div className="col-span-1 flex-col flex gap-y-.5 w-1/2">
                <Input
                  type="text"
                  name="endPage"
                  autoComplete="off"
                  style={{
                    backgroundColor: "#080808",
                  }}
                  value={formData.endPage}
                  placeholder="End Page"
                  onChange={handleNumberInput}
                  size="large"
                  className="border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-3  text-white bg-bgColor customInputBlack makeBgBlack"
                />
                {errors.endPage && (
                  <p className="text-red-500 text-xs">{errors.endPage}</p>
                )}
              </div>
            </div>

            <div className="col-span-1 flex-col flex gap-y-.5 ">
              <Input
                type="text"
                name="totalPages"
                autoComplete="off"
                style={{
                  backgroundColor: "#080808",
                }}
                value={formData.totalPages}
                placeholder="Total Pages"
                onChange={handleNumberInput}
                size="large"
                className="border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-3  text-white bg-bgColor customInputBlack makeBgBlack"
              />
              {errors.totalPages && (
                <p className="text-red-500 text-xs">{errors.totalPages}</p>
              )}
            </div>

            <div className="col-span-1 flex-col flex gap-y-.5">
              <Input.TextArea
                name="description"
                rows={2}
                autoComplete="off"
                style={{
                  backgroundColor: "#080808",
                }}
                value={formData.description}
                placeholder="Chapter Description"
                onChange={handleChange}
                size="large"
                className="border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-3  text-white bg-bgColor customInputBlack makeBgBlack"
              />
              {errors.description && (
                <p className="text-red-500 text-xs">{errors.description}</p>
              )}
            </div>

            <div className="col-span-1 flex-col flex gap-y-.5 ">
              <Input
                type="text"
                name="audioBookStartingPage"
                autoComplete="off"
                style={{
                  backgroundColor: "#080808",
                }}
                value={formData.audioBookStartingPage}
                placeholder="Audio Book Starting Page Number"
                onChange={handleNumberInput}
                size="large"
                className="border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-3  text-white bg-bgColor customInputBlack makeBgBlack"
              />
            </div>

            <div className="col-span-1 flex-col flex gap-y-.5 ">
              <UploadFile
                placeholder={`Click or drag to upload ${
                  type === "Edit" ? "a new" : "a"
                } chapter file (PDF, max 100MB)`}
                accept=".pdf"
                maxFileSize={100 * 1024 * 1024}
                image={formData.chapterFile ? formData.chapterFile : null}
                onFileChange={(file) => handleInputChange("chapterFile", file)}
                onImageRemove={() => handleInputChange("chapterFile", null)}
              />
            </div>

            <div className="col-span-1 flex-col flex gap-y-.5 ">
              <UploadFile
                placeholder={`Click or drag to upload ${
                  type === "Edit" ? "a new" : "a"
                } chapter audio File (MP3, max 50MB)`}
                accept=".mp3"
                maxFileSize={50 * 1024 * 1024}
                image={formData.audioFile || null}
                onFileChange={(file) => handleAudioFile(file)}
                onImageRemove={() => handleInputChange("audioFile", null)}
              />
            </div>

            <span className="flex justify-center gap-x-2 mt-2 pb-4">
              <ButtonDefault
                size={4}
                variant={ButtonVariants.GRAY}
                className="p-1 w-1/3"
                rounded={true}
                onClick={() => handleCancel()}
              >
                <span className="flex text-sm font-bold gap-x-2 px-4 py-1 whitespace-nowrap">
                  Cancel
                </span>
              </ButtonDefault>
              <ButtonDefault
                size={4}
                variant={ButtonVariants.WHITE}
                className="p-1 w-2/3"
                disabled={confirmLoading || audioLoading}
                loading={confirmLoading || audioLoading}
                rounded={true}
                onClick={handleSubmit}
              >
                <span className="flex text-sm font-bold gap-x-2 px-4 py-1 whitespace-nowrap">
                  {type} Chapter
                </span>
              </ButtonDefault>
            </span>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default ChapterModal;
