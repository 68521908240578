import React, { useState } from "react";
import { Modal, Button, Table, Tooltip } from "antd";
import { CopyOutlined } from "@ant-design/icons";

interface DebugModalProps {
  visible: boolean;
  onClose: () => void;
  data: { _id: string; start: string; end: string; page: number }[];
}

const DebugModal: React.FC<DebugModalProps> = ({ visible, onClose, data }) => {
  const [pagination, setPagination] = useState({ current: 1, pageSize: 5 });

  const handleTableChange = (newPagination: any) => {
    setPagination({
      current: newPagination.current,
      pageSize: newPagination.pageSize,
    });
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(JSON.stringify(data, null, 2));
  };

  const columns = [
    { title: "ID", dataIndex: "_id", key: "_id", ellipsis: true },
    { title: "Start", dataIndex: "start", key: "start" },
    { title: "End", dataIndex: "end", key: "end" },
    { title: "Page", dataIndex: "page", key: "page" },
  ];

  return (
    <Modal
      title="Debug Data"
      open={visible}
      onCancel={onClose}
      footer={null}
      width={800}
      className="rounded-xl"
    >
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-semibold">Data Overview</h2>
        <Tooltip title="Copy JSON">
          <Button
            icon={<CopyOutlined />}
            onClick={copyToClipboard}
            className=" "
          />
        </Tooltip>
      </div>

      <Table
        dataSource={data}
        columns={columns}
        rowKey="_id"
        size="small"
        pagination={{
          current: pagination.current,
          pageSize: pagination.pageSize,
          showSizeChanger: true,
          pageSizeOptions: ["5", "10", "20", "50"],
        }}
        onChange={handleTableChange}
        bordered
      />
    </Modal>
  );
};

export default DebugModal;
