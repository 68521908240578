import { Input, Modal, Select, Switch } from "antd";
import React, { useEffect, useState } from "react";
import ButtonDefault, { ButtonVariants } from "../../shared/basic/button";
import { notify } from "../../shared/basic/notify";
import { formateErrorObject } from "../../shared/formateErrorObject";
import {
  createBook,
  editBook,
  getAllAuthors,
  getAllCategories,
  getAllProducts,
  getSingleBook,
} from "../../../services/dashboard";
import UploadFile from "../UploadFile";
import extractErrorMessage from "../../shared/basic/formateError";
import { generateUrlForImage } from "../Books";
import { Endpoints } from "../../../network";
import { ShouldRender } from "../../shared/basic/ShouldRender";

interface ModalComponentProps {
  visible: boolean;
  onCancel: () => void;
  isClosable?: boolean;
  type?: string;
  bookId?: number;
}

const BookModal: React.FC<ModalComponentProps> = ({
  visible,
  onCancel,
  type,
  bookId,
}) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [authors, setAuthors] = useState<
    {
      value: string;
      label: string;
    }[]
  >();
  const [audioLoading, setAudioLoading] = useState(false);
  const [categories, setCategories] = useState<
    {
      value: string;
      label: string;
    }[]
  >();
  const [products, setProducts] = useState<
    {
      productId: string;
      hardcoverProductId: string;
      digitalProductId: string;
      slug: string;
      name: string;
      description: string;
      externalLink: string;
    }[]
  >();

  const [formData, setFormData] = useState<{
    title: string;
    author: {
      name: string | undefined;
      value: string;
    };
    product: {
      productId: string;
      hardcoverProductId: string;
      digitalProductId: string;
      slug: string;
      name?: string;
    };
    isExternalLink: boolean;
    description: string;
    type: string;
    longDescription: string;
    totalPages: number | undefined;
    externalLink: string;
    coverImage: any;
    category_id: string;
    samplePdf: any;
    slug: string;
    isListed: boolean;
    featured: boolean;
    sampleBookTimestamps?: { page: number; start: string; end: string }[];
    audioBookSample?: any;
    completeBookPdf?: any;
    completeBookAudio?: any;
    completeBookTimestamps?: { page: number; start: string; end: string }[];
    completeBookAudioStartingPage?: number;
    sampleBookAudioStartingPage?: number;
  }>({
    title: "",
    author: {
      name: undefined,
      value: "",
    },
    product: {
      productId: "",
      hardcoverProductId: "",
      digitalProductId: "",
      slug: "",
      name: "",
    },
    isExternalLink: false,
    description: "",
    type: "",
    longDescription: "",
    category_id: "",
    totalPages: undefined,
    externalLink: "",
    coverImage: undefined,
    samplePdf: undefined,
    slug: "",
    isListed: false,
    featured: false,
    audioBookSample: null,
    sampleBookTimestamps: [],
    completeBookPdf: null,
    completeBookAudio: null,
    completeBookTimestamps: [],
    completeBookAudioStartingPage: undefined,
    sampleBookAudioStartingPage: undefined,
  });

  const [errors, setErrors] = useState({
    title: "",
    author: "",
    product: "",
    category_id: "",
    description: "",
    type: "",
    longDescription: "",
    isExternalLink: "",
    totalPages: "",
    externalLink: "",
    coverImage: "",
    samplePdf: "",
    slug: "",
    isListed: "",
    featured: "",
    completeBookAudioStartingPage: "",
    completeBookTimestamps: "",
    sampleBookAudioStartingPage: "",
    sampleBookTimestamps: "",
  });

  const handleCancel = () => {
    onCancel();
    setErrors({
      title: "",
      author: "",
      product: "",
      category_id: "",
      description: "",
      type: "",
      longDescription: "",
      totalPages: "",
      externalLink: "",
      coverImage: "",
      samplePdf: "",
      isExternalLink: "",
      slug: "",
      isListed: "",
      featured: "",
      completeBookAudioStartingPage: "",
      completeBookTimestamps: "",
      sampleBookAudioStartingPage: "",
      sampleBookTimestamps: "",
    });
    setFormData({
      title: "",
      author: {
        name: undefined,
        value: "",
      },
      product: {
        productId: "",
        hardcoverProductId: "",
        digitalProductId: "",
        slug: "",
        name: "",
      },
      isExternalLink: false,
      description: "",
      type: "",
      longDescription: "",
      category_id: "",
      totalPages: undefined,
      externalLink: "",
      coverImage: undefined,
      samplePdf: undefined,
      slug: "",
      isListed: false,
      featured: false,
      audioBookSample: null,
      sampleBookTimestamps: [],
      completeBookPdf: null,
      completeBookAudio: null,
      completeBookTimestamps: [],
      completeBookAudioStartingPage: undefined,
      sampleBookAudioStartingPage: undefined,
    });
  };

  const handleOk = () => {
    setConfirmLoading(true);

    setTimeout(() => {
      setConfirmLoading(false);
      handleCancel();
    }, 2000);
  };

  const baseUrl = process.env.REACT_APP_BASE_URL;

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const sanitizeValue = (value: any, fallback: any) =>
    value === "null" || value === null ? fallback : value;

  const isValidURL = (url: string) => {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/i;
    return urlRegex.test(url);
  };
  const handleAudioFile = async (
    file: File | null,
    fieldName: { audio: string; timeStamp: string }
  ) => {
    if (!file) {
      handleInputChange(fieldName.audio, null);
      handleInputChange(fieldName.timeStamp, []);
      return;
    }
    setAudioLoading(true);

    handleInputChange(fieldName.audio, file);

    // const audio = new Audio(URL.createObjectURL(file));

    // audio.onloadedmetadata = async () => {
    //   const totalDuration = audio.duration;
    //   const silences = await detectSilences(file);
    //   const generatedTimestamps = await mapTimestampsToPages(
    //     silences,
    //     totalDuration
    //   );

    //   handleInputChange(fieldName.timeStamp, generatedTimestamps);
    // };
    setAudioLoading(false);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const validationErrors = {
      title: "",
      author: "",
      product: "",
      description: "",
      type: "",
      category_id: "",
      longDescription: "",
      totalPages: "",
      externalLink: "",
      coverImage: "",
      samplePdf: "",
      isExternalLink: "",
      slug: "",
      isListed: "",
      featured: "",
      completeBookAudioStartingPage: "",
      completeBookTimestamps: "",
      sampleBookAudioStartingPage: "",
      sampleBookTimestamps: "",
    };
    if (!formData.title) {
      validationErrors.title = "Please Enter Title";
    }
    if (!formData.author.name) {
      validationErrors.author = "Please select author";
    }
    if (!formData.product.productId) {
      validationErrors.product = "Please select product";
    }
    if (!formData?.category_id?.length) {
      validationErrors.category_id = "Please select category";
    }
    if (!formData.type) {
      validationErrors.type = "Please select type";
    }
    if (!formData.totalPages) {
      validationErrors.totalPages = "Please enter total pages";
    }
    if (!formData.description) {
      validationErrors.description = "Please enter description";
    }
    if (!formData.longDescription) {
      validationErrors.longDescription = "Please enter Long Description";
    }
    if (!formData.slug) {
      validationErrors.slug = "Please enter slug";
    }

    // if (formData.audioBookSample && !formData.sampleBookTimestamps) {
    //   validationErrors.sampleBookTimestamps =
    //     "sampleBookTimestamps is required";
    // }

    if (formData.audioBookSample && !formData.sampleBookAudioStartingPage) {
      validationErrors.sampleBookAudioStartingPage =
        "sampleBookAudioStartingPage is required";
    }

    // if (formData.completeBookAudio && !formData.completeBookTimestamps) {
    //   validationErrors.completeBookTimestamps =
    //     "completeBookTimestamps is required";
    // }

    if (formData.completeBookAudio && !formData.completeBookAudioStartingPage) {
      validationErrors.completeBookAudioStartingPage =
        "completeBookAudioStartingPage is required";
    }
    if (formData.type !== "comingSoon") {
      if (
        !formData.externalLink &&
        formData.type === "long" &&
        !isValidURL(formData.externalLink)
      ) {
        validationErrors.externalLink = "Please enter valid external url";
      }
    }

    if (!formData.coverImage) {
      validationErrors.coverImage = "Please enter cover image";
    }
    if (
      !formData.samplePdf &&
      type !== "Edit" &&
      formData.type !== "comingSoon"
    ) {
      validationErrors.samplePdf = "Please enter sample pdf";
    }

    setErrors(validationErrors);

    if (Object.values(validationErrors).every((error) => !error)) {
      try {
        setConfirmLoading(true);
        if (type !== "Edit") {
          await createBook({
            title: formData.title,
            authors: formData.author.name as string,
            author_list: [formData.author.value],
            productId: +formData.product.productId,
            coverImage: formData.coverImage,
            description: formData.description,
            digitalProductId: +formData.product.digitalProductId,
            externalLink: formData.externalLink,
            hardcoverProductId: +formData.product.hardcoverProductId,
            longDescription: formData.longDescription,
            slug: formData.slug,
            totalPages: formData.totalPages,
            isExternalLink: formData?.isExternalLink,
            type: formData.type,
            isListed: formData.isListed,
            featured: formData.featured,
            categories: [formData.category_id],
            audioBookSample: formData.audioBookSample,
            // sampleBookTimestamps: formData.sampleBookTimestamps,
            completeBookAudio: formData.completeBookAudio,
            completeBookPdf: formData.completeBookPdf,
            // completeBookTimestamps: formData.completeBookTimestamps,
            completeBookAudioStartingPage:
              formData.completeBookAudioStartingPage,
            sampleBookAudioStartingPage: formData.sampleBookAudioStartingPage,
            ...(formData.type !== "comingSoon" && {
              samplePdf: formData.samplePdf,
            }),
          });
        } else {
          await editBook(bookId?.toString() as string, {
            title: formData.title,
            authors: formData.author.name as string,
            author_list: [formData.author.value],
            productId: +formData.product.productId,
            coverImage: formData.coverImage,
            description: formData.description,
            digitalProductId: +formData.product.digitalProductId,
            externalLink: formData.externalLink,
            hardcoverProductId: +formData.product.hardcoverProductId,
            longDescription: formData.longDescription,
            isExternalLink: formData?.isExternalLink,
            slug: formData.slug,
            totalPages: formData.totalPages,
            type: formData.type,
            featured: formData.featured,
            isListed: formData.isListed,
            categories: [formData.category_id],
            audioBookSample: formData.audioBookSample,
            // sampleBookTimestamps: formData.sampleBookTimestamps,
            completeBookAudio: formData.completeBookAudio,
            completeBookPdf: formData.completeBookPdf,
            // completeBookTimestamps: formData.completeBookTimestamps,
            completeBookAudioStartingPage:
              formData.completeBookAudioStartingPage
                ? +formData.completeBookAudioStartingPage
                : undefined,
            sampleBookAudioStartingPage: formData.sampleBookAudioStartingPage
              ? +formData.sampleBookAudioStartingPage
              : undefined,
            ...(formData.type !== "comingSoon" && {
              samplePdf: formData.samplePdf,
            }),
          });
        }

        notify("Book Created Successfully", "success");
        if (typeof window !== "undefined") {
          window.location.reload();
        }

        handleCancel();
      } catch (error: any) {
        const message = formateErrorObject(error);
        notify(message, "error");
      } finally {
        setConfirmLoading(false);
      }
    }
  };

  const handleSelectChange = ({
    value,
    key,
    name,
  }: {
    value: string;
    key?: string;
    name: string;
  }) => {
    if (name === "author") {
      setFormData({
        ...formData,
        author: {
          name: key as string,
          value: value,
        },
      });
    } else if (name === "products") {
      const product = products?.filter(
        (product) => product.productId === value
      );
      if (product?.length) {
        setFormData({
          ...formData,
          product: {
            productId: product[0]?.productId as string,
            hardcoverProductId: product[0]?.hardcoverProductId as string,
            digitalProductId: product[0]?.digitalProductId as string,
            slug: product[0]?.slug as string,
          },
          description: product[0]?.description as string,
          title: product[0]?.name as string,
          externalLink: product[0]?.externalLink as string,
          slug: product[0]?.slug as string,
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleAllAuthors = async () => {
    try {
      const authors = await getAllAuthors();

      setAuthors(
        authors?.data?.map((author: any) => ({
          value: author?._id,
          label: author?.name,
        }))
      );
    } catch (error) {
      console.error("API call error:", error);
    }
  };

  const handleAllCategories = async () => {
    try {
      const categories = await getAllCategories();
      setCategories(
        categories?.data?.map((category: any) => ({
          value: category?._id,
          label: category?.name,
        }))
      );
    } catch (error) {
      console.error("API call error:", error);
    }
  };

  const environment = process.env.REACT_APP_PUBLIC_APP_ENV;

  const handleAllProducts = async (type: string) => {
    const isLong = type === "long";

    try {
      const filter = isLong
        ? { book_type: "hardcover", category_id: 6 }
        : type === "comingSoon"
        ? { book_type: "digital", category_id: 6 }
        : { sub_category_id: environment === "production" ? 14 : 13 };

      const products = await getAllProducts(filter);

      setProducts(
        products?.data?.map((product: any) => ({
          productId: isLong ? product?.digital_pair?.id : product?.id,
          hardcoverProductId: isLong ? product?.id : product?.id,
          digitalProductId: isLong ? product?.digital_pair?.id : product?.id,
          slug: product?.slug,
          name: product?.name,
          description: product?.description,
          externalLink: product?.external_link,
        }))
      );
    } catch (error) {
      console.error("API call error:", error);
    }
  };

  useEffect(() => {
    if (formData.type && formData.type !== "") {
      handleAllProducts(formData.type);
    }
  }, [formData.type]);

  useEffect(() => {
    if (visible) {
      handleAllAuthors();
      handleAllCategories();
    }
  }, [visible]);

  const handleInputChange = (
    name: string,
    value:
      | string
      | File
      | undefined
      | null
      | []
      | { page: number; start: string; end: string }[]
  ) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleNumberInput = (name: string, value: string) => {
    const inputValue = value;
    const cleanedValue = inputValue.replace(/\D/g, "");
    setFormData({ ...formData, [name]: +cleanedValue });
  };

  const handleSingleBook = async (bookId: string) => {
    try {
      //   setLoading(true);
      const response = await getSingleBook(bookId);
      const data = response.data;
      const product = products?.filter(
        (product) => product.productId === data.productId
      );
      setFormData({
        ...formData,
        coverImage: generateUrlForImage(
          `${baseUrl}/`,
          `${Endpoints.getCoverImagesForBooks}/`,
          `${data?._id}`
        ),
        description: data?.description,
        externalLink: data?.externalLink,
        longDescription: data?.longDescription,
        type: data?.type,
        totalPages: data?.totalPages,
        isExternalLink: data?.isExternalLink,
        category_id: data?.categories?.length && data?.categories[0]?._id,
        title: data?.title,
        slug: data?.slug,
        featured: data?.featured,
        author: {
          name: data?.author_list?.[0]?.name,
          value: data?.author_list?.[0]?._id,
        },
        isListed: data?.isListed,
        product: {
          slug: product?.length ? (product[0]?.slug as string) : "",
          digitalProductId: data?.digitalProductId,
          productId: data?.productId,
          hardcoverProductId: data?.hardcoverProductId,
        },
        samplePdf: sanitizeValue(data?.samplePdf, undefined),
        audioBookSample: sanitizeValue(data?.audioBookSample, undefined),
        sampleBookTimestamps: sanitizeValue(data?.sampleBookTimestamps, []),
        completeBookPdf: sanitizeValue(data?.completeBookPdf, undefined),
        completeBookAudio: sanitizeValue(data?.completeBookAudio, undefined),
        completeBookTimestamps: sanitizeValue(data?.completeBookTimestamps, []),
        completeBookAudioStartingPage: sanitizeValue(
          data?.completeBookAudioStartingPage,
          undefined
        ),
        sampleBookAudioStartingPage: sanitizeValue(
          data?.sampleBookAudioStartingPage,
          undefined
        ),
      });
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      //   setLoading(false);
    }
  };

  useEffect(() => {
    if (bookId) {
      handleSingleBook(bookId.toString());
    }
  }, [bookId]);

  return (
    <Modal
      open={visible}
      centered={true}
      onOk={handleOk}
      closable={false}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      destroyOnClose
      footer={false}
      className="customLoginModal bg-bgColor bg-opacity-70  pt-5  relative rounded-2xl border-2 border-borderDarkGray backdrop-filter backdrop-blur-md "
    >
      <div className="flex items-center flex-col justify-center px-5 md:px-10">
        <div className={` z-30  flex flex-col gap-y-1 w-full `}>
          <div className="pb-3 flex flex-col gap-y-2 ">
            <h1 className="text-xl flex justify-center gap-x-2 font-medium lg:font-normal lg:text-2xl mt-1 leading-10 text-fadedWhite text-center w-full">
              {type} Book
            </h1>
          </div>

          <form className="flex flex-col mt-2 gap-y-3 h-full  w-full ">
            <div className="flex max-w-full gap-x-2 h-full">
              <div className="col-span-1 flex-col flex gap-y-.5 w-1/2">
                <Select
                  style={{ height: 40 }}
                  value={formData.type || undefined}
                  placeholder="Select Book Type"
                  onChange={(value) =>
                    handleSelectChange({
                      value,
                      name: "type",
                    })
                  }
                  className="rounded-xl border border-bgDarkGray  antdSelect "
                  options={[
                    {
                      value: "long",
                      label: "Long Books",
                    },
                    {
                      value: "short",
                      label: "Short Books",
                    },
                    {
                      value: "comingSoon",
                      label: "Coming Soon",
                    },
                  ]}
                />
                {errors.type && (
                  <p className="text-red-500 text-xs">{errors.type}</p>
                )}
              </div>
              <div className="col-span-1 flex-col flex gap-y-.5 w-1/2">
                <Select
                  showSearch
                  style={{ height: 40 }}
                  value={formData?.product?.productId || undefined}
                  placeholder="Select Product Type"
                  filterOption={(input, option) =>
                    (option?.name ?? "").includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.name ?? "")
                      ?.toLowerCase()
                      .localeCompare((optionB?.name ?? "")?.toLowerCase())
                  }
                  onChange={(value) =>
                    handleSelectChange({
                      value,
                      name: "products",
                    })
                  }
                  // disabled={}
                  className="rounded-xl border border-bgDarkGray  antdSelect "
                  options={products?.map((product) => ({
                    value: product?.productId,
                    label: product?.name,
                    name: product?.name?.toLowerCase(),
                  }))}
                />
                {errors.product && (
                  <p className="text-red-500 text-xs">{errors.product}</p>
                )}
              </div>
            </div>

            <div className="flex w-full gap-x-2">
              <div className="col-span-1 w-full flex gap-y-.5">
                <Input
                  type="text"
                  name="title"
                  autoComplete="off"
                  style={{
                    backgroundColor: "#080808",
                  }}
                  value={formData.title}
                  placeholder="Title"
                  onChange={handleChange}
                  size="large"
                  className="border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-3  text-white bg-bgColor customInputBlack makeBgBlack"
                />
                {errors.title && (
                  <p className="text-red-500 text-xs">{errors.title}</p>
                )}
              </div>

              <div className="col-span-1 flex-col flex gap-y-.5 w-full">
                <span className="border-bgDarkGray justify-between p-2 border rounded-lg h-full flex items-center">
                  <p className="font-semibold">
                    {!formData.featured ? "Set As Featured" : "Featured"}
                  </p>
                  <Switch
                    checked={formData.featured}
                    onChange={(checked) =>
                      setFormData({
                        ...formData,
                        featured: checked,
                      })
                    }
                    className="bg-lightgray"
                  />
                </span>

                {errors.featured && (
                  <p className="text-red-500 text-xs">{errors.featured}</p>
                )}
              </div>
            </div>
            <div className="col-span-1 flex-col flex gap-y-.5 w-full">
              <Select
                showSearch
                style={{ height: 40 }}
                placeholder="Select Author"
                filterOption={(input, option) =>
                  (option?.name ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.name ?? "")
                    ?.toLowerCase()
                    ?.localeCompare((optionB?.name ?? "")?.toLowerCase())
                }
                value={formData.author.name || undefined}
                onChange={(value, option) =>
                  handleSelectChange({
                    value,
                    key: (option as { value: string; label: string })
                      ?.label as string,
                    name: "author",
                  })
                }
                className="rounded-xl border border-bgDarkGray  antdSelect "
                options={authors?.map((product) => ({
                  value: product?.value,
                  label: product?.label,
                  name: product?.label?.toLowerCase(),
                }))}
              />

              {errors.author && (
                <p className="text-red-500 text-xs">{errors.author}</p>
              )}
            </div>
            <div className="flex w-full gap-x-2">
              <div className="col-span-1 flex-col flex gap-y-.5 w-full">
                <Select
                  style={{ height: 40 }}
                  showSearch
                  value={formData.category_id || undefined}
                  placeholder="Select Category"
                  onChange={(value) =>
                    handleSelectChange({
                      value,
                      name: "category_id",
                    })
                  }
                  filterOption={(input, option) =>
                    (option?.name ?? "").includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.name ?? "")
                      ?.toLowerCase()
                      ?.localeCompare((optionB?.name ?? "")?.toLowerCase())
                  }
                  className="rounded-xl border border-bgDarkGray  antdSelect "
                  dropdownRender={(menu) => <>{menu}</>}
                  options={categories?.map((product) => ({
                    value: product?.value,
                    label: product?.label,
                    name: product?.label?.toLowerCase(),
                  }))}
                />
                {errors.category_id && (
                  <p className="text-red-500 text-xs">{errors.category_id}</p>
                )}
              </div>
              <div className="col-span-1 flex-col flex gap-y-.5 w-full">
                <span className="border-bgDarkGray justify-between p-2 border rounded-lg h-full flex items-center">
                  <p className="font-semibold">
                    {!formData.isListed ? "Un-List" : "Listed"}
                  </p>
                  <Switch
                    checked={formData.isListed}
                    onChange={(checked) =>
                      setFormData({
                        ...formData,
                        isListed: checked,
                      })
                    }
                    className="bg-lightgray"
                  />
                </span>

                {errors.isListed && (
                  <p className="text-red-500 text-xs">{errors.isListed}</p>
                )}
              </div>
            </div>
            <div className="flex w-full gap-x-2">
              <div className="col-span-1 flex-col flex gap-y-.5 w-full">
                <Input
                  type="text"
                  name="slug"
                  autoComplete="off"
                  style={{
                    backgroundColor: "#080808",
                  }}
                  value={formData.slug}
                  placeholder="slug"
                  onChange={handleChange}
                  size="large"
                  className="border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-3  text-white bg-bgColor customInputBlack makeBgBlack"
                />
                {errors.slug && (
                  <p className="text-red-500 text-xs">{errors.slug}</p>
                )}
              </div>
              <ShouldRender check={formData?.type === "long"}>
                <div className="col-span-1 flex-col flex gap-y-.5 w-full">
                  <span className="border-bgDarkGray justify-between p-2 border rounded-lg h-full flex items-center">
                    <p className="font-semibold">
                      {!formData.isExternalLink
                        ? "WebFlow-Page"
                        : "Landing-Page"}
                    </p>
                    <Switch
                      checked={formData.isExternalLink}
                      onChange={(checked) =>
                        setFormData({
                          ...formData,
                          isExternalLink: checked,
                        })
                      }
                      className="bg-lightgray"
                    />
                  </span>

                  {errors.isExternalLink && (
                    <p className="text-red-500 text-xs">
                      {errors.isExternalLink}
                    </p>
                  )}
                </div>
              </ShouldRender>
            </div>

            <div className="flex w-full gap-x-2">
              <div className="col-span-1 flex-col flex gap-y-.5 w-full">
                <Input
                  type="text"
                  name="totalPages"
                  autoComplete="off"
                  style={{
                    backgroundColor: "#080808",
                  }}
                  value={formData.totalPages}
                  placeholder="Total Pages"
                  onChange={(e) =>
                    handleNumberInput("totalPages", e.target.value)
                  }
                  size="large"
                  className="border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-3  text-white bg-bgColor customInputBlack makeBgBlack"
                />
                {errors.totalPages && (
                  <p className="text-red-500 text-xs">{errors.totalPages}</p>
                )}
              </div>

              <div className="col-span-1 flex-col flex gap-y-.5 w-full">
                <Input
                  type="text"
                  name="externalLink"
                  autoComplete="off"
                  style={{
                    backgroundColor: "#080808",
                  }}
                  value={formData.externalLink}
                  placeholder="External Link"
                  onChange={handleChange}
                  size="large"
                  className="border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-3  text-white bg-bgColor customInputBlack makeBgBlack"
                />
                {errors.externalLink && (
                  <p className="text-red-500 text-xs">{errors.externalLink}</p>
                )}
              </div>
            </div>

            <div className="col-span-1 flex-col flex gap-y-.5">
              <Input
                type="text"
                name="description"
                autoComplete="off"
                style={{
                  backgroundColor: "#080808",
                }}
                value={formData.description}
                placeholder="Description"
                onChange={handleChange}
                size="large"
                className="border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-3  text-white bg-bgColor customInputBlack makeBgBlack"
              />
              {errors.description && (
                <p className="text-red-500 text-xs">{errors.description}</p>
              )}
            </div>
            <div className="col-span-1 flex-col flex gap-y-.5">
              <Input.TextArea
                name="longDescription"
                rows={2}
                autoComplete="off"
                style={{
                  backgroundColor: "#080808",
                }}
                value={formData.longDescription}
                placeholder="Long Description"
                onChange={handleChange}
                size="large"
                className="border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-3  text-white bg-bgColor customInputBlack makeBgBlack"
              />
              {errors.longDescription && (
                <p className="text-red-500 text-xs">{errors.longDescription}</p>
              )}
            </div>
            <div className="col-span-1 flex-col flex gap-y-.5 w-full">
              <UploadFile
                placeholder="Click or drag to upload a cover image (WEBP, max 1MB)"
                accept=".webp"
                image={formData.coverImage ? formData.coverImage : null}
                onFileChange={(file) => handleInputChange("coverImage", file)}
                onImageRemove={() => handleInputChange("coverImage", null)}
                isSession={true}
                maxFileSize={1 * 1024 * 1024}
              />
              {errors.coverImage && (
                <p className="text-red-500 text-xs">{errors.coverImage}</p>
              )}
            </div>
            <div className="col-span-1 flex-col flex gap-y-.5 h-full">
              <Input
                type="text"
                name="sampleBookAudioStartingPage"
                autoComplete="off"
                style={{
                  backgroundColor: "#080808",
                }}
                value={formData.sampleBookAudioStartingPage}
                placeholder="Audio Sample Book Starting Page Number"
                onChange={(e) =>
                  handleNumberInput(
                    "sampleBookAudioStartingPage",
                    e.target.value
                  )
                }
                size="large"
                className="border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-3  text-white bg-bgColor customInputBlack makeBgBlack"
              />
              {errors.sampleBookAudioStartingPage && (
                <p className="text-red-500 text-xs">
                  {errors.sampleBookAudioStartingPage}
                </p>
              )}
            </div>
            <div className="flex w-full gap-x-2 h-full ">
              <ShouldRender check={formData?.type !== "comingSoon"}>
                <div className=" gap-y-.5 w-full">
                  <UploadFile
                    accept=".pdf"
                    placeholder={`Click or drag to upload ${
                      type === "Edit" && "New"
                    } sample PDF (max 100MB)`}
                    maxFileSize={100 * 1024 * 1024}
                    image={formData.samplePdf ? formData.samplePdf : null}
                    onFileChange={(file) =>
                      handleInputChange("samplePdf", file)
                    }
                    onImageRemove={() => handleInputChange("samplePdf", null)}
                  />
                  {errors.samplePdf && (
                    <p className="text-red-500 text-xs">{errors.samplePdf}</p>
                  )}
                </div>
                <div className="col-span-1 flex-col flex gap-y-.5 ">
                  <UploadFile
                    placeholder={`Click or drag to upload ${
                      type === "Edit" && "New"
                    } Sample Audio File (max 50MB)`}
                    accept=".mp3"
                    maxFileSize={50 * 1024 * 1024}
                    image={formData.audioBookSample || null}
                    onFileChange={(file) =>
                      handleAudioFile(file, {
                        audio: "audioBookSample",
                        timeStamp: "sampleBookTimestamps",
                      })
                    }
                    onImageRemove={() =>
                      handleInputChange("audioBookSample", null)
                    }
                  />
                </div>
              </ShouldRender>
            </div>
            <div className="col-span-1 flex-col flex gap-y-.5 ">
              <Input
                type="text"
                name="completeBookAudioStartingPage"
                autoComplete="off"
                style={{
                  backgroundColor: "#080808",
                }}
                value={formData.completeBookAudioStartingPage}
                placeholder="Audio Complete Book Starting Page Number"
                onChange={(e) =>
                  handleNumberInput(
                    "completeBookAudioStartingPage",
                    e.target.value
                  )
                }
                size="large"
                className="border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-3  text-white bg-bgColor customInputBlack makeBgBlack"
              />
              {errors.completeBookAudioStartingPage && (
                <p className="text-red-500 text-xs">
                  {errors.completeBookAudioStartingPage}
                </p>
              )}
            </div>
            <div className="flex w-full gap-x-2">
              <ShouldRender check={formData?.type !== "comingSoon"}>
                <div className="col-span-1 flex-col flex gap-y-.5 w-full">
                  <UploadFile
                    accept=".pdf"
                    placeholder={`Click or drag to upload ${
                      type === "Edit" && "New"
                    } Complete Book PDF (max 2GB)`}
                    maxFileSize={2 * 1024 * 1024 * 1024}
                    image={formData.completeBookPdf ?? null}
                    onFileChange={(file) =>
                      handleInputChange("completeBookPdf", file)
                    }
                    onImageRemove={() =>
                      handleInputChange("completeBookPdf", null)
                    }
                  />
                </div>
                <div className="col-span-1 flex-col flex gap-y-.5 ">
                  <UploadFile
                    placeholder={`Click or drag to upload ${
                      type === "Edit" && "New"
                    } Complete Audio File (max 2GB)`}
                    accept=".mp3"
                    maxFileSize={2 * 1024 * 1024 * 1024}
                    image={formData.completeBookAudio || null}
                    onFileChange={(file) =>
                      handleAudioFile(file, {
                        audio: "completeBookAudio",
                        timeStamp: "completeBookTimestamps",
                      })
                    }
                    onImageRemove={() =>
                      handleInputChange("completeBookAudio", null)
                    }
                  />
                </div>
              </ShouldRender>
            </div>

            <span className="flex justify-center gap-x-2 mt-2 pb-4">
              <ButtonDefault
                size={4}
                variant={ButtonVariants.GRAY}
                className="p-1 w-1/3"
                rounded={true}
                onClick={() => handleCancel()}
              >
                <span className="flex text-sm font-bold gap-x-2 px-4 py-1 whitespace-nowrap">
                  Cancel
                </span>
              </ButtonDefault>
              <ButtonDefault
                size={4}
                variant={ButtonVariants.WHITE}
                className="p-1 w-2/3"
                disabled={
                  confirmLoading ||
                  audioLoading ||
                  (formData.completeBookAudio &&
                    formData?.completeBookTimestamps?.length === 0) ||
                  (formData.audioBookSample &&
                    formData?.sampleBookTimestamps?.length === 0)
                }
                loading={
                  confirmLoading ||
                  audioLoading ||
                  (formData.completeBookAudio &&
                    formData?.completeBookTimestamps?.length === 0) ||
                  (formData.audioBookSample &&
                    formData?.sampleBookTimestamps?.length === 0)
                }
                rounded={true}
                onClick={handleSubmit}
              >
                <span className="flex text-sm font-bold gap-x-2 px-4 py-1 whitespace-nowrap">
                  {type} Book
                </span>
              </ButtonDefault>
            </span>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default BookModal;
