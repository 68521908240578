import { RcFile } from "antd/es/upload";
import { Endpoints } from "../../network";
import apiClient from "../base";

export interface IReview {
  limit: number;
  page: number;
  product_id: string;
  sortByDate?: string;
  zl_uid?: string;
  sortByRating?: string;
}

export interface IReviewBody {
  rating: number;
  title: string;
  text: string;
  images: (string | RcFile | undefined)[];
  userName: string;
  userId: string;
  userImage?: string;
}

const fetchReviewData = async (
  endpoint: string,
  { limit, page, product_id, sortByDate, zl_uid, sortByRating }: IReview
) => {
  const queryParams = new URLSearchParams({
    limit: String(limit),
    page: String(page),
    ...(sortByDate && { sortByDate }),
    ...(sortByRating && { sortByRating }),

    ...(zl_uid && { zl_uid: zl_uid as string }),
  });

  const response = await apiClient.get(
    `${endpoint}/${product_id}?${queryParams}`
  );
  return response?.data?.data;
};

export const handleDeleteReview = async (reviewId: string) => {
  const response = await apiClient.delete(
    `${Endpoints.getAllProductReviews}/${reviewId}`
  );
  return response?.data?.data;
};

export const getAllReviews = async (params: IReview) => {
  return fetchReviewData(`${Endpoints.getAllProductReviews}`, params);
};

export const updateReview = async (
  data: Partial<IReviewBody>,
  reviewId: string,
  imageIdForDelete: string[]
) => {
  const formData = new FormData();

  // Append only defined values
  const appendField = (key: string, value: any) => {
    if (value != null) formData.append(key, value);
  };

  appendField("rating", data.rating?.toString());
  appendField("title", data.title);
  appendField("text", data.text);
  appendField("userName", data.userName);
  appendField("userId", data.userId);
  appendField("userImage", data.userImage);
  if (imageIdForDelete?.length) {
    imageIdForDelete.forEach((id) => {
      formData.append("imageIdForDelete[]", id);
    });
  }

  const convertToFiles = async (images: (string | File | undefined)[]) => {
    return images
      ?.map((image) => {
        return image instanceof File ? image : undefined;
      })
      .filter((image): image is File => image !== undefined);
  };

  if (data?.images) {
    const files = await convertToFiles(data.images);
    files.forEach((file) => {
      if (file) formData.append("images", file);
    });
  }

  const response = await apiClient.put(
    `${Endpoints.getAllProductReviews}/${reviewId}`,
    formData
  );

  return response?.data?.data;
};
