import React, { useEffect, useState } from "react";
import {
  getProductsList,
  getShortBooksList,
} from "../../../services/dashboard";
import extractErrorMessage from "../../shared/basic/formateError";
import { notify } from "../../shared/basic/notify";
import { useRecoilValue } from "recoil";
import { IProductListData } from "../../../utils/interfaces/dashboard";
import ProductCard from "../ProductCard";
import { Pagination, PaginationProps, Spin } from "antd";
import { ShouldRender } from "../../shared/basic/ShouldRender";
import { searchKeywords } from "../../../atoms/searchKeywords";
import { debounce } from "lodash";
import { Endpoints } from "../../../network";

export const generateUrlForImage = (
  baseUrl: string,
  endpoint: string,

  id: string
): string => {
  const encodedId = encodeURIComponent(id);
  const url = `${baseUrl}${endpoint}${encodedId}`;
  return url;
};

const Books: React.FC<{
  bookType: string;
  visible: boolean;
  setVisibleBook: React.Dispatch<
    React.SetStateAction<{ type: string; value: boolean; bookId?: number }>
  >;
}> = ({ bookType, visible = false, setVisibleBook }) => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  const searchKeyword = useRecoilValue(searchKeywords);

  const [productListData, setProductListData] = useState<IProductListData>();

  const baseUrl = process.env.REACT_APP_BASE_URL;

  const handleProductList = async (keyword?: string) => {
    try {
      setLoading(true);
      let response = null;
      if (bookType === "short") {
        response = await getShortBooksList({
          page,
          name: keyword,
          book_type: bookType,
          isListed: false,
        });
      } else {
        response = await getProductsList({
          page,
          name: keyword,
          book_type: bookType,
          isListed: false,
        });
      }

      setProductListData({
        current_page: response?.data?.metadata?.page,
        per_page: response?.data?.metadata?.limit,
        total: response?.data?.metadata?.total,
        products: response?.data?.data
          ?.map((product: any) => {
            return {
              image: generateUrlForImage(
                `${baseUrl}/`,
                `${Endpoints.getCoverImagesForBooks}/`,
                `${product?._id}`
              ),
              title: product?.title,
              id: product?._id,
              desc: product?.description,
              externalLink: product?.external_link,
              slug: product?.slug,
              type: product?.type,
              digitalProductId: product?.digitalProductId,
              hardProductId: product?.hardcoverProductId,
              completeAudioBookTimeStamp: product?.completeBookTimestamps,
            };
          })
          .filter((book: any) => book.type === bookType),
      });
      // response?.meta?.current_page && setPage(response?.meta?.current_page);
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      setLoading(false);
    }
  };

  const delayedSearch = debounce((keyword: string) => {
    handleSearch(keyword);
  }, 500);
  const handleSearch = async (keyword: string) => {
    if (keyword !== undefined) {
      handleProductList(keyword);
    }
  };
  useEffect(() => {
    delayedSearch(searchKeyword);
    return () => {
      delayedSearch.cancel();
    };
  }, [searchKeyword]);
  useEffect(() => {
    handleProductList();
  }, [page]);

  const onChange: PaginationProps["onChange"] = (pageNumber: number) => {
    setPage(pageNumber);
  };

  useEffect(() => {
    if (visible) {
      handleProductList();
    }
  }, [visible]);

  return (
    <div>
      <div className="relative  rounded-xl flex flex-col gap-y-5 mt-5">
        <ShouldRender check={!loading}>
          {productListData?.products?.map((product) => {
            return (
              <span key={product?.id}>
                <ProductCard
                  data={product}
                  setVisibleBook={setVisibleBook}
                  bookType={bookType}
                />
              </span>
            );
          })}
          <div className="flex justify-end pt-5">
            <Pagination
              current={productListData?.current_page}
              total={productListData?.total}
              pageSize={productListData?.per_page || 0}
              onChange={onChange}
            />
          </div>
        </ShouldRender>
        <ShouldRender check={loading}>
          <span className="py-28 flex justify-center items-center">
            {" "}
            <Spin size="large" />
          </span>
        </ShouldRender>
      </div>
    </div>
  );
};
export default Books;
