/* eslint-disable react/no-unescaped-entities */
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { Button, Modal } from "antd";
import React from "react";
import { notify } from "../../shared/basic/notify";
import extractErrorMessage from "../../shared/basic/formateError";
import {
  deleteCategory,
  deleteSingleAuthors,
} from "../../../services/dashboard";
import { deleteBanner, deleteBannerPage } from "../../../services/banner";
import { deleteLandingPage } from "../../../services/landingPage";

interface Props {
  visible: { value: boolean; id: string };
  type?: string;
  closeModal: () => void;
  handleCategoryList?: any;
  pageId?: string;
  bannerId?: string;
}

const CategoryModalDelete: React.FC<Props> = ({
  closeModal,
  visible,
  type = "Category",
  handleCategoryList,
  pageId,
  bannerId,
}) => {
  const handleDelete = async (categoryId: string) => {
    try {
      if (type === "Author") {
        await deleteSingleAuthors(categoryId);
      } else if (type === "Banner") {
        await deleteBanner(pageId as string, bannerId as string);
      } else if (type === "BannerPage") {
        await deleteBannerPage(categoryId);
      } else if (type === "Landing Page") {
        await deleteLandingPage(visible?.id);
      } else {
        await deleteCategory(categoryId);
      }
      notify(`${type} deleted successfully!`, "success");
      closeModal();
      await handleCategoryList();
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    }
  };
  return (
    <Modal
      open={visible.value}
      maskClosable={false}
      closable={false}
      footer={null}
      onCancel={closeModal}
    >
      <div className="flex flex-col gap-3 w-full">
        <span className="text-center flex justify-center">
          <ExclamationCircleIcon className="text-yellow-500 w-12 h-12" />
        </span>
        <div className="flex justify-center w-full text-center">
          <div className=" justify-center flex flex-col w-full text-lg pb-4">
            <span>
              Are you sure? You want to delete this {type?.toLowerCase()} ?
            </span>{" "}
            <span>Action once completed can not be changed</span>
          </div>
        </div>

        <span className="flex justify-center gap-x-2">
          <Button onClick={() => closeModal()}>No</Button>
          <Button
            danger
            type="primary"
            onClick={() => {
              handleDelete(visible?.id);
            }}
          >
            Yes, I'm sure
          </Button>
        </span>
      </div>
    </Modal>
  );
};

export default CategoryModalDelete;
