import { XIcon } from "@heroicons/react/outline";
import { Form, Input, message, Modal, Rate } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { notify } from "../../shared/basic/notify";
import { formateErrorObject } from "../../shared/formateErrorObject";
import { IReviewsData } from "..";
import ButtonDefault, { ButtonVariants } from "../../shared/basic/button";
import { updateReview } from "../../../services/Review";

interface CreateReviewModalProps {
  isModalOpen: boolean;
  handleClose: () => void;

  review?: IReviewsData | undefined;
  fetchData: () => Promise<void>;
}

interface FormValues {
  rating: number;
  review: string;
}

const EditReviewModal: React.FC<CreateReviewModalProps> = ({
  isModalOpen,
  handleClose,
  review,
  // fetchData,
}) => {
  const [formData, setFormData] = useState({
    rating: 0,
    review: "",
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm<FormValues>();

  const [deletedImage, setDeletedImage] = useState<string[]>([]);
  const screens = useBreakpoint();
  const { productId } = useParams();

  const handleInputChange = (changedValues: any) => {
    const { review: reviewText } = changedValues;
    const sanitizedReview = reviewText;

    setFormData((prev) => ({
      ...prev,
      ...changedValues,
      review: sanitizedReview,
    }));
  };

  const handleSubmit = async () => {
    const { rating, review: reviewText } = formData;

    if (!productId || reviewText?.length < 0) {
      message.error("Review and rating are required.");
      return;
    }

    setLoading(true);

    try {
      const updatedFields = {
        ...(rating !== review?.rating && { rating }),
        ...(reviewText !== review?.text && { text: reviewText }),
      };

      if (Object.keys(updatedFields).length > 0) {
        await updateReview(updatedFields, review?._id as string, deletedImage);
      }

      if (window !== undefined) {
        window.location.reload();
      }
      handleCancel();
    } catch (error: any) {
      notify(formateErrorObject(error), "error");
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    handleClose();
    setFormData({
      rating: 0,
      review: "",
    });
    setDeletedImage([]);
    form.setFieldsValue({
      rating: 0,
      review: "",
    });
  };

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      rating: review?.rating as number,
      review: review?.text as string,
    }));

    form.setFieldsValue({
      rating: review?.rating,
      review: review?.text,
    });
  }, [review, isModalOpen]);

  const isDisabled = form
    .getFieldsError()
    .some((field) => field.errors.length > 0);

  return (
    <Modal
      closable={false}
      centered
      open={isModalOpen}
      onCancel={handleCancel}
      footer={
        <div className="flex gap-x-5 pb-4 pt-4 md:pt-2 px-7">
          <ButtonDefault
            size={4}
            rounded={true}
            className="w-1/3"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleCancel();
            }}
            variant={ButtonVariants.GRAY}
          >
            <span className="flex text-sm gap-x-2 text-[#4B4B4B] px-4 whitespace-nowrap">
              Cancel
            </span>
          </ButtonDefault>
          <ButtonDefault
            size={4}
            loading={loading}
            rounded={true}
            disabled={isDisabled}
            className="w-2/3"
            onClick={() => handleSubmit()}
            variant={ButtonVariants.WHITE}
          >
            <span className="flex text-sm gap-x-2 px-4 whitespace-nowrap">
              Submit
            </span>
          </ButtonDefault>
        </div>
      }
      width={450}
      destroyOnClose
      className="customLoginModal  bg-[#131313] bg-opacity-70 h-full md:h-fit  md:rounded-2xl border-2 border-borderDarkGray backdrop-filter backdrop-blur-md "
    >
      <div className="text-fadedWhite text-xl  h-full">
        <div className="flex justify-between items-center  bg-backGroundReaderTabBg md:rounded-t-2xl px-4 md:px-8 py-4 xl:py-6">
          <div className="flex flex-col gap-1">
            <span>Write a Review</span>
            <span className="text-textGray text-sm ">
              Help someone find their next best read
            </span>
          </div>

          <span className="border rounded-full p-1.5 border-[#363636] flex justify-center items-center">
            <XIcon
              onClick={() => handleCancel()}
              className="w-5 h-5  text-[#A6A6A6] cursor-pointer"
            />
          </span>
        </div>
        <div className="px-7 pt-7  pb-2">
          <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            initialValues={{ rating: 0, review: "", images: [] }}
            onValuesChange={handleInputChange}
            className="space-y-4 "
          >
            <Form.Item
              name="rating"
              label={
                <span className="text-sm font-semibold">
                  How would you rate it?
                </span>
              }
              rules={[
                { required: true, message: "Please provide a rating!" },
                {
                  validator: (_, value) =>
                    value && value > 0
                      ? Promise.resolve()
                      : Promise.reject("Please provide a rating!"),
                },
              ]}
            >
              <Rate
                style={{ fontSize: "22px" }}
                className="text-[#00A86B] ml-1"
                allowClear
                value={formData.rating}
                allowHalf
              />
            </Form.Item>

            <Form.Item
              name="review"
              // label={
              //   <span className='text-sm font-semibold'>
              //     What did you think?
              //   </span>
              // }
              rules={[
                { required: true, message: "Please write a review!" },
                // {
                //   validator: (_, value) =>
                //     value && value.length >= 100
                //       ? Promise.resolve()
                //       : Promise.reject(
                //           'Review must be at least 100 characters.',
                //         ),
                // },
              ]}
            >
              <Input.TextArea
                rows={screens.lg ? 5 : 8}
                placeholder="Write your review, what did you like or dislike?"
                className=" text-white bg-transparent"
              />
            </Form.Item>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default EditReviewModal;
