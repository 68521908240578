import React, { ReactNode } from "react";
import { AuthBg, LogoWhite } from "../../assets";

interface Props {
  children?: ReactNode;
}

const AuthLayout: React.FC<Props> = ({ children }) => {
  const commissionBenefits: string[] = [
    '<span style="color: #FF5612">Books: </span> Add, Update, Remove Effortlessly',
    '<span style="color: #FF5612">Authors:</span>  Manage, Track, Reward Seamlessly',
  ];
  return (
    <div className="lg:flex min-h-full flex-1">
      <div className="relative hidden w-0 text-white flex-1 lg:block">
        <div className="flex justify-start text-start w-full px-10 pt-8">
          <img src={LogoWhite} alt="Logo" className=" flex h-3.5 w-fit" />
        </div>
        <div className="pl-10 xl:px-28 pt-24 z-10">
          <h2 className="text-5xl font-semibold">Reader`s Admin</h2>
          <p className="text-textDarkGrayColor text-2xl">
            Effortlessly manage books and authors
          </p>
          <ul className="list-disc pl-7 pt-8 flex flex-col gap-y-4 text-2xl w-2/3">
            {commissionBenefits.map((benefit, index) => (
              <li key={index} dangerouslySetInnerHTML={{ __html: benefit }} />
            ))}
          </ul>
        </div>

        <img
          className="absolute -z-10 xl:pl-40 xl:pt-40  inset-0 h-full w-full bottom-0 right-0 object-cover"
          src={AuthBg}
          alt=""
        />
      </div>
      <span className="z-50 w-5/12 bg-readerLightGrayBg">{children}</span>
    </div>
  );
};
export default AuthLayout;
