import React, { useEffect, useState } from "react";

import { Button, Empty, Input, Select, Table, Tag } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Helmet } from "react-helmet";
import { pageTitle } from "../../components/shared/basic/customHead";
import CategoryModalDelete from "../../components/Categories/CategoryModalDelete";
import CreateBannerPage from "../../components/Banner/CreateBannerPage";
import { useNavigate } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import {
  getAllLandingPage,
  updateLandingPageStatus,
} from "../../services/landingPage";
import extractErrorMessage from "../../components/shared/basic/formateError";
import { notify } from "../../components/shared/basic/notify";
import { getAllAuthors } from "../../services/dashboard";

export interface IStatus {
  status: "draft" | "publish";
}

interface BookData {
  name: string;
  category: string;
  author: string;
  status: string;
  id: string;
  productSlug: string;
}

export function getUserInitials(fullName: string): string {
  if (!fullName) return "";
  const allNames = fullName.trim().split(" ");
  const initials = allNames.reduce((acc, curr, index) => {
    if (index === 0 || index === allNames.length - 1) {
      acc = `${acc}${curr.charAt(0).toUpperCase()}`;
    }
    return acc;
  }, "");
  return initials;
}

const BookLandingPage: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [authors, setAuthors] = useState<
    {
      value: string;
      label: string;
    }[]
  >();

  const [deleteBanner, setDeleteBanner] = useState<{
    value: boolean;
    id: string;
  }>({
    id: "",
    value: false,
  });
  const [data, setData] = useState<BookData[]>([]);
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [filteredData, setFilteredData] = useState<BookData[]>(data);
  const [visibleBanner, setVisibleBanner] = useState<{
    type: string;
    value: boolean;
    pageId?: string;
  }>({ type: "", value: false });
  const router = useNavigate();

  // const screens = useBreakpoint()

  const handleAllAuthors = async () => {
    try {
      const authors = await getAllAuthors();

      setAuthors(
        authors?.map((author: any) => ({
          value: author?._id,
          label: author?.name,
        }))
      );
    } catch (error) {
      console.error("API call error:", error);
    }
  };

  const handlePageStatus = async (id: string, status: string) => {
    try {
      await updateLandingPageStatus(id, status);
      await handleAllLandingPages();
    } catch (error) {
      console.error("API call error:", error);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = e.target.value.toLowerCase();
    setSearchKeyword(keyword);
    filterData(keyword);
  };

  // Filter data based on keyword
  const filterData = (keyword: string) => {
    if (keyword) {
      const filtered = data.filter((item) =>
        item.name.toLowerCase().includes(keyword)
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(data); // Reset to original data if keyword is empty
    }
  };

  const onCancelAuthorModal = () => {
    setVisibleBanner({ type: "", value: false });
  };
  const onCancelCategoryDelete = () => {
    setDeleteBanner({ value: false, id: "" });
  };

  const getAuthorLabelById = (id: string) => {
    const author = authors?.find((author) => author.value === id);
    return author ? author.label : "Unknown Author";
  };

  const columns = [
    {
      title: (
        <span className="text-xs flex py-2 justify-start md:pl-10">Books</span>
      ),

      key: "name",
      render: (record: any) => {
        return (
          <div
            onClick={() => router(`/book-landing-page/${record.productSlug}`)}
            className="flex gap-x-4 capitalize items-center font-medium md:pl-10 justify-start hover:cursor-pointer hover:underline text-center"
          >
            {record?.name}
          </div>
        );
      },
    },
    {
      title: (
        <span className="text-xs flex py-2 justify-center ">Category</span>
      ),

      key: "category",
      render: (record: any) => {
        const getColor = (category: string) => {
          return category ? "yellow" : "green";
        };
        return (
          <div className="flex justify-center">
            <Tag color={getColor(record?.category)}>
              {record?.category ? "Coming Soon" : "Live Book"}
            </Tag>
          </div>
        );
      },
    },
    {
      title: (
        <span className="text-xs flex justify-center text-center">Author</span>
      ),

      key: "Author",
      render: (record: any) => {
        return (
          <div
            // onClick={() => router(`/bannerPages/${record.value}`)}
            className="flex gap-x-4 capitalize items-center font-medium justify-center hover:cursor-pointer  text-center"
          >
            {getAuthorLabelById(record?.author)}
          </div>
        );
      },
    },

    {
      title: (
        <span className="text-xs flex justify-center text-center">Status</span>
      ),

      key: "Edit_action",
      render: (record: any) => {
        return (
          <div className="flex font-medium gap-x-2 justify-center ">
            <Select
              defaultValue="Draft"
              value={record?.status}
              style={{ width: 120 }}
              onChange={(value) => handlePageStatus(record?.id, value as any)}
              options={[
                { value: "publish", label: "Publish" },
                { value: "draft", label: "Draft" },
              ]}
            />
            <Button
              danger
              onClick={() =>
                setDeleteBanner({
                  id: record.id,
                  value: true,
                })
              }
            >
              Delete Page
            </Button>
          </div>
        );
      },
    },
  ];

  const handleAllLandingPages = async () => {
    try {
      setLoading(true);
      const landingPages = await getAllLandingPage();
      await handleAllAuthors();
      setData(
        landingPages?.data?.map((item: any) => ({
          name: item?.name,
          category: item?.isUpcoming,
          author: item?.authorName,
          status: item?.status,
          id: item?._id,
          productSlug: item?.productSlug,
        }))
      );
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleAllLandingPages();
  }, []);

  const rowClassName = (_: any, index: number) => {
    return index % 2 === 0 ? "bg-[#131313]" : "bg-bgColor";
  };

  return (
    <div className="bg-readerLightGrayBg rounded-xl ">
      <Helmet>
        <title>{pageTitle("Book Landing Pages")}</title>
      </Helmet>
      <div className="py-4 px-6 flex flex-col justify-between md:flex-row gap-x-2 items-center">
        <Input
          size="large"
          placeholder="Search Pages"
          className="rounded-lg customSearch w-1/3 text-gray-100 text-sm placeholder:text-gray-400 h-10 bg-readerLightGrayBg"
          value={searchKeyword}
          prefix={<SearchOutlined className="w-5 h-5 pr-1 text-[#A6A6A6]" />}
          onChange={handleInputChange}
        />

        <span>
          <Button
            onClick={() => router("/book-landing-page/create")}
            icon={<PlusOutlined />}
          >
            Create Page
          </Button>
        </span>
      </div>

      <div className="customScrollBar ">
        <Table
          dataSource={searchKeyword.length ? filteredData : data}
          loading={loading}
          className={`tableHeader  rounded-none customTable overflow-auto customScrollBar`}
          columns={columns}
          pagination={false}
          rowClassName={rowClassName}
          locale={{
            emptyText: (
              <span>
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={<span>No Banners</span>}
                  className={`h-40 flex justify-center items-center flex-col`}
                />
              </span>
            ),
          }}
        />
      </div>
      <CreateBannerPage
        type={visibleBanner.type}
        page={visibleBanner.pageId}
        onCancel={onCancelAuthorModal}
        visible={visibleBanner.value as boolean}
      />
      <CategoryModalDelete
        type={"Landing Page"}
        visible={deleteBanner as { value: boolean; id: string }}
        closeModal={onCancelCategoryDelete}
        handleCategoryList={handleAllLandingPages}
      />
    </div>
  );
};

export default BookLandingPage;
