import { Helmet } from "react-helmet";
import React from "react";
import { pageTitle } from "../../components/shared/basic/customHead";
import Review from "../../components/Review";

const ReviewsPage = () => {
  return (
    <div>
      <Helmet>
        <title>{pageTitle("Reviews")}</title>
      </Helmet>
      <Review />
    </div>
  );
};

export default ReviewsPage;
