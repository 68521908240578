import React from 'react';

interface CustomIconProps {
  filled?: boolean;
}

const SortIcon: React.FC<CustomIconProps> = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M11.9948 8.66146H3.99479C3.59479 8.66146 3.32812 8.39479 3.32812 7.99479C3.32812 7.59479 3.59479 7.32812 3.99479 7.32812H11.9948C12.3948 7.32812 12.6615 7.59479 12.6615 7.99479C12.6615 8.39479 12.3948 8.66146 11.9948 8.66146Z'
      fill='#737373'
    />
    <path
      d='M9.99479 12.6615H5.99479C5.59479 12.6615 5.32812 12.3948 5.32812 11.9948C5.32812 11.5948 5.59479 11.3281 5.99479 11.3281H9.99479C10.3948 11.3281 10.6615 11.5948 10.6615 11.9948C10.6615 12.3948 10.3948 12.6615 9.99479 12.6615Z'
      fill='#737373'
    />
    <path
      d='M13.9948 4.66146H1.99479C1.59479 4.66146 1.32812 4.39479 1.32812 3.99479C1.32812 3.59479 1.59479 3.32812 1.99479 3.32812H13.9948C14.3948 3.32812 14.6615 3.59479 14.6615 3.99479C14.6615 4.39479 14.3948 4.66146 13.9948 4.66146Z'
      fill='#737373'
    />
  </svg>
);

export default SortIcon;
