import React, { useEffect, useState } from "react";
import ButtonDefault, { ButtonVariants } from "../../shared/basic/button";
import clsx from "clsx";
import Parser from "../../shared/Parser";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Checkbox,
  Divider,
  Dropdown,
  Input,
  MenuProps,
  Radio,
} from "antd";
import { DownOutlined, CopyOutlined } from "@ant-design/icons";
import { notify } from "../../shared/basic/notify";
import { ShouldRender } from "../../shared/basic/ShouldRender";
import { getAllReviews } from "../../../services/Review";
import { formateErrorObject } from "../../shared/formateErrorObject";
import CompleteBookTimestampModal from "../CompleteBookTimestampModal";

interface IProductCard {
  data: {
    image: string;
    title: number;
    id: number;
    desc?: string;
    externalLink?: string;
    slug?: string;
    digitalProductId?: number;
    hardProductId?: number;
    completeAudioBookTimeStamp: {
      _id: string;
      start: string;
      end: string;
      page: number;
    }[];
  };
  isCourse?: boolean;
  bookType: string;

  setVisibleBook: React.Dispatch<
    React.SetStateAction<{ type: string; value: boolean; bookId?: number }>
  >;
}

const ProductCard: React.FC<IProductCard> = ({
  data,
  isCourse = false,
  setVisibleBook,
  bookType,
}) => {
  const { image, title, desc, hardProductId, digitalProductId } = data;
  const router = useNavigate();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [couponCodeValue, setCouponCodeValue] = useState<string>();
  const [reviewCount, setReviewCount] = useState(0);
  const [isModalOpen, setModalOpen] = useState(false);

  const fetchReviewData = async () => {
    try {
      const response = await getAllReviews({
        page: 1,
        limit: 999999,
        product_id: data?.id?.toString(),
      });
      setReviewCount(response.meta.totalRecords);
    } catch (error: any) {
      notify(formateErrorObject(error), "error");
    }
  };

  useEffect(() => {
    if (data?.id && bookType === "long") {
      fetchReviewData();
    }
  }, [data]);

  const [selectedItems, setSelectedItems] = useState<{
    [key: string]: boolean;
  }>({
    buy_now: false,
    cp: false,
  });

  const [mode, setMode] = useState<string>("hard");

  const menuStyle: React.CSSProperties = {
    boxShadow: "none",
  };

  const handleCheckboxChange = (key: string, checked: boolean) => {
    setSelectedItems((prev) => ({
      ...prev,
      [key]: checked,
    }));
  };

  const handleModeChange = (e: any) => {
    setMode(e.target.value);
  };

  const handleCopyURL = () => {
    const selectedParams = Object.entries(selectedItems)
      .filter(([, isSelected]) => isSelected)
      .map(([key]) => {
        if (key === "buy_now") {
          return "buy_now=true";
        } else if (key === "cp") {
          return `cp=${couponCodeValue ?? "XXXX"}`;
        } else {
          return key;
        }
      })
      .join("&");

    const productType =
      mode === "digital"
        ? `add-to-cart=${digitalProductId}`
        : `add-to-cart=${hardProductId}`;
    const defaultMode = process.env.REACT_APP_PUBLIC_APP_ENV;
    const url =
      defaultMode?.toString() === "production"
        ? "https://zebralearn.com"
        : "https://staging.zebralearn.com";
    const staticURL = `${url}/shop/?${productType}&${selectedParams}`;

    navigator.clipboard.writeText(staticURL);
    setDropdownVisible(false);
    setCouponCodeValue(undefined);
    setSelectedItems({
      buy_now: false,
      cp: false,
    });
    notify("Link Copied Successfully", "success");
  };

  const handleCouponCodeInput = (value: string) => {
    if (value.trim()) {
      handleCheckboxChange("cp", true);
    }
    setCouponCodeValue(value);
  };
  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <span
          onClick={(e) => {
            e.stopPropagation();
          }}
          className="no-select"
        >
          <Checkbox
            checked={selectedItems.buy_now}
            onChange={(e) => handleCheckboxChange("buy_now", e.target.checked)}
          >
            Buy Now ( 2nd step )
          </Checkbox>
        </span>
      ),
    },
    {
      key: "2",
      label: (
        <span
          onClick={(e) => {
            e.stopPropagation();
          }}
          className="no-select"
        >
          <Checkbox
            checked={selectedItems.cp}
            onChange={(e) => handleCheckboxChange("cp", e.target.checked)}
          >
            <Input
              placeholder="Coupon Code"
              value={couponCodeValue}
              onChange={(e) => handleCouponCodeInput(e.target.value)}
            />
          </Checkbox>
        </span>
      ),
    },
  ];

  return (
    <div className="flex flex-col md:flex-row justify-between border-b gap-x-5 border-b-bgLightGray">
      <span className="flex gap-x-4 py-5 w-full">
        <img
          src={image}
          className={clsx(
            !isCourse ? "w-28 h-36 rounded-sm" : "w-52 h-28 rounded-lg"
          )}
        />
        <div className="flex gap-y-2  w-full">
          <span className="lg:py-6  w-full">
            <p className="font-semibold text-xl">{title}</p>
            <p className=" lg:w-1/2 text-textDarkGrayColor  ">
              <Parser
                maxWords={20}
                text={desc?.replace(/<[^>]*>?/gm, "") || ""}
              />
            </p>
          </span>
        </div>
      </span>
      <div className="flex flex-col items-center gap-y-2">
        <span className="grid grid-cols-2 gap-2 items-center ">
          <ButtonDefault
            size={2}
            variant={ButtonVariants.WHITE}
            className="p-1 w-full"
            onClick={() => {
              router(`/products/${data?.id}`);
            }}
          >
            <span rel="noreferrer" className="px-5 whitespace-nowrap">
              Chapters
            </span>
          </ButtonDefault>

          <ButtonDefault
            size={2}
            variant={ButtonVariants.GRAY}
            onClick={() =>
              setVisibleBook({ type: "Edit", value: true, bookId: data.id })
            }
            className="p-1 w-full"
          >
            {" "}
            <span className="px-5 whitespace-nowrap text-white hover:text-gray-200">
              Edit Book
            </span>
          </ButtonDefault>
          <ShouldRender check={bookType === "long"}>
            <Dropdown
              menu={{ items }}
              trigger={["click"]}
              open={dropdownVisible}
              onOpenChange={(visible) => setDropdownVisible(visible)}
              dropdownRender={(menu) => (
                <div className="bg-bgLightGray p-1 rounded-lg">
                  <Radio.Group
                    onChange={handleModeChange}
                    value={mode}
                    className="mb-1 bg-bgLightGray border-bgLightGray text-white flex gap-2 rounded-md overflow-hidden border-2 auth-style"
                  >
                    <Radio.Button
                      className={clsx(
                        "w-1/2 border-none rounded-md text-center",
                        mode === "hard"
                          ? "bg-white font-medium"
                          : "bg-transparent text-white "
                      )}
                      value="hard"
                    >
                      Hard Copy
                    </Radio.Button>
                    <Radio.Button
                      className={clsx(
                        "w-1/2 border-none rounded-md text-center",
                        mode === "digital"
                          ? "bg-white text-green-600 font-medium"
                          : "bg-transparent text-white "
                      )}
                      value="digital"
                    >
                      Digital Copy
                    </Radio.Button>
                  </Radio.Group>

                  {React.cloneElement(menu as React.ReactElement<any>, {
                    style: menuStyle,
                  })}
                  <Divider
                    style={{
                      margin: "7px 0 7px 0",
                    }}
                  />
                  <div className="pb-2 px-1">
                    <Button
                      type="primary"
                      className="w-full"
                      icon={<CopyOutlined />}
                      onClick={handleCopyURL}
                    >
                      Copy URL
                    </Button>
                  </div>
                </div>
              )}
            >
              <Button
                size="large"
                className="w-full"
                onClick={(e) => e.preventDefault()}
              >
                <span rel="noreferrer" className=" flex gap-x-2 ">
                  Copy Link <DownOutlined classID="text-sm" />
                </span>
              </Button>
            </Dropdown>
            <ButtonDefault
              size={2}
              variant={ButtonVariants.WHITE}
              className="p-1 w-full"
              onClick={() => {
                router(`/reviews/${data?.id}`);
              }}
            >
              <span rel="noreferrer" className="px-5 whitespace-nowrap">
                Reviews: {reviewCount}
              </span>
            </ButtonDefault>
          </ShouldRender>
        </span>
        <ButtonDefault
          size={2}
          variant={ButtonVariants.GRAY}
          onClick={() => setModalOpen(true)}
          className="p-1 w-full"
        >
          <span className="px-5 whitespace-nowrap text-white hover:text-gray-200">
            Complete AudioBook Timestamp
          </span>
        </ButtonDefault>
        <CompleteBookTimestampModal
          visible={isModalOpen}
          onClose={() => setModalOpen(false)}
          data={data?.completeAudioBookTimeStamp}
        />
      </div>
    </div>
  );
};

export default ProductCard;
