import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Select,
  Space,
  Popconfirm,
  Empty,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { formatErrorObject } from "../../../utils/lib/auth";
import {
  createCategoryAPI,
  deleteCategoryAPI,
  getCategoriesAPI,
  updateCategoryAPI,
} from "../../../services/personalityTest";
import { notify } from "../../shared/basic/notify";
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { ShouldRender } from "../../shared/basic/ShouldRender";

export interface Category {
  _id: string;
  name: string;
  valueTypes: string[];
}

export enum CategoryType {
  ONBOARDING = "onboarding",
  PERSONALITY = "personality",
}

interface Props {
  type?: CategoryType;
}

const CategoryTable: React.FC<Props> = ({ type }) => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingCategory, setEditingCategory] = useState<Category | null>(null);

  const [form] = Form.useForm();
  const router = useNavigate();
  const getCategories = async (type?: CategoryType) => {
    try {
      const data = await getCategoriesAPI(type);
      setCategories(data?.data);
    } catch (error) {
      notify(formatErrorObject(error), "error");
    }
  };

  const handleCreateCategory = async (data: Partial<Category>) => {
    try {
      await createCategoryAPI(data, type);
      notify("Category created successfully", "success");
      getCategories(type);
    } catch (error) {
      notify(formatErrorObject(error), "error");
    }
  };

  const handleUpdateCategory = async (id: string, data: Partial<Category>) => {
    try {
      await updateCategoryAPI(id, data);
      notify("Category updated successfully", "success");
      getCategories(type);
    } catch (error) {
      notify(formatErrorObject(error), "error");
    }
  };

  const handleDeleteCategory = async (id: string) => {
    try {
      await deleteCategoryAPI(id);
      notify("Category deleted successfully", "success");
      getCategories(type);
    } catch (error) {
      notify(formatErrorObject(error), "error");
    }
  };

  const openModal = (category?: Category) => {
    setIsModalOpen(true);
    setEditingCategory(category || null);
    form.setFieldsValue(category || { name: "", valueTypes: [] });
  };

  const closeModal = () => {
    setIsModalOpen(false);
    form.resetFields();
    setEditingCategory(null);
  };

  const handleModalSubmit = async () => {
    try {
      const values = await form.validateFields();
      if (editingCategory) {
        await handleUpdateCategory(editingCategory._id, values);
      } else {
        await handleCreateCategory(values);
      }
      closeModal();
    } catch (error) {
      notify(formatErrorObject(error), "error");
    }
  };

  const columns: ColumnsType<Category> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Value Types",
      dataIndex: "valueTypes",
      key: "valueTypes",
      render: (valueTypes) => valueTypes.join(", "),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => {
        const handleNavigate = () => {
          const valueTypesString = record.valueTypes.join(", ");
          router(
            `${type ? "/onboarding-questions" : "/personality-type"}/${
              record._id
            }?valueTypes=${encodeURIComponent(valueTypesString)}`
          );
        };
        return (
          <Space>
            <Button type="link" onClick={() => openModal(record)}>
              Edit
            </Button>
            <Button type="text" onClick={handleNavigate}>
              View Details
            </Button>
            <Popconfirm
              title="Delete this category? All related questions and options will be removed.?"
              onConfirm={() => handleDeleteCategory(record._id)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="text" danger>
                Delete
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    getCategories(type).finally(() => setLoading(false));
  }, []);

  const rowClassName = (_: any, index: number) => {
    return index % 2 === 0 ? "bg-[#131313]" : "bg-bgColor";
  };
  return (
    <div className="bg-readerLightGrayBg rounded-xl ">
      <div className="py-4 px-6 flex flex-col justify-between md:flex-row gap-x-2 items-center">
        <p className="font-semibold flex justify-start text-start text-base lg:text-lg whitespace-nowrap">
          All Categories{" "}
        </p>
        <span className="flex gap-x-4">
          <ShouldRender check={!type}>
            <Button onClick={() => router("/personality-type/results")}>
              Results Screens
            </Button>
          </ShouldRender>

          <Button
            onClick={() =>
              router(
                `${
                  type ? "/onboarding-questions" : "/personality-type"
                }/user-submission`
              )
            }
          >
            View User Submissions
          </Button>
          <Button onClick={() => openModal()} icon={<PlusOutlined />}>
            Create Categories
          </Button>
        </span>
      </div>
      <div className="customScrollBar">
        <Table
          columns={columns}
          dataSource={categories}
          rowKey="_id"
          className={`tableHeader  rounded-none customTable overflow-auto customScrollBar`}
          loading={loading}
          rowClassName={rowClassName}
          bordered
          locale={{
            emptyText: (
              <span>
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={<span>No Category</span>}
                  className={`h-40 flex justify-center items-center flex-col`}
                />
              </span>
            ),
          }}
          pagination={false}
        />
      </div>

      <Modal
        title={editingCategory ? "Edit Category" : "Add Category"}
        open={isModalOpen}
        onCancel={closeModal}
        onOk={handleModalSubmit}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            label="Name"
            name="name"
            rules={[
              { required: true, message: "Please enter the category name" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Value Types"
            name="valueTypes"
            rules={[
              {
                required: true,
                message: "Please enter at least one value type",
              },
            ]}
          >
            <Select mode="tags" placeholder="Enter value types" />
          </Form.Item>
          <ShouldRender check={type}>
            <Form.Item
              label="Form Type"
              name="formType"
              rules={[
                {
                  required: true,
                  message: "Please select a form type",
                },
              ]}
            >
              <Select placeholder="Select form type">
                <Select.Option value="Radio Button">Radio Button</Select.Option>
                <Select.Option value="Select">Select</Select.Option>
                <Select.Option value="MultiSelect">MultiSelect</Select.Option>
              </Select>
            </Form.Item>
          </ShouldRender>
        </Form>
      </Modal>
    </div>
  );
};

export default CategoryTable;
