import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Space,
  Popconfirm,
  Select,
  Empty,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import {
  createQuestionAPI,
  deleteQuestionAPI,
  getQuestionsAPI,
  updateQuestionAPI,
  createOptionAPI,
  deleteOptionAPI,
} from "../../../services/personalityTest";
import { notify } from "../../shared/basic/notify";
import { PlusOutlined } from "@ant-design/icons";
import { formatErrorObject } from "../../../utils/lib/auth";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/outline";
import { CategoryType } from "../CategoryTable";

export interface Question {
  _id: string;
  text: string;
  options: Option[];
  category?: string;
}

export interface Option {
  _id: string;
  label: string;
  value: string;
  question?: string;
}
interface Props {
  type?: CategoryType;
}

const QuestionTable: React.FC<Props> = ({ type }) => {
  const [questions, setQuestions] = useState<Question[]>([]);
  const [loading, setLoading] = useState(false);
  const [isQuestionModalOpen, setIsQuestionModalOpen] = useState(false);
  const [isOptionModalOpen, setIsOptionModalOpen] = useState(false);
  const [editingQuestion, setEditingQuestion] = useState<Question | null>(null);
  const [editingOption, setEditingOption] = useState<Partial<Option> | null>(
    null
  );
  const [form] = Form.useForm();
  const [optionForm] = Form.useForm();
  const { categoryId } = useParams();
  const location = useLocation();
  const router = useNavigate();

  const searchParams = new URLSearchParams(location.search);
  const valueTypes = searchParams.get("valueTypes");

  const fetchQuestions = async () => {
    setLoading(true);
    try {
      const data = await getQuestionsAPI(categoryId as string);

      setQuestions(data?.data?.data || []);
    } catch (error) {
      notify(formatErrorObject(error), "error");
    } finally {
      setLoading(false);
    }
  };

  const handleCreateOrUpdateQuestion = async (data: Partial<Question>) => {
    try {
      if (editingQuestion) {
        await updateQuestionAPI(editingQuestion._id, data);
        notify("Question updated successfully", "success");
      } else {
        await createQuestionAPI({ ...data, category: categoryId });
        notify("Question created successfully", "success");
      }
      fetchQuestions();
    } catch (error) {
      notify(formatErrorObject(error), "error");
    }
  };

  const handleDeleteQuestion = async (id: string) => {
    try {
      await deleteQuestionAPI(id);
      notify("Question deleted successfully", "success");
      fetchQuestions();
    } catch (error) {
      notify(formatErrorObject(error), "error");
    }
  };

  const handleCreateOption = async (
    questionId: string,
    option: Partial<Option>
  ) => {
    try {
      await createOptionAPI({ ...option, question: questionId });
      notify("Option added successfully", "success");
      fetchQuestions();
    } catch (error) {
      notify(formatErrorObject(error), "error");
    }
  };

  const handleDeleteOption = async (optionId: string) => {
    try {
      await deleteOptionAPI(optionId);
      notify("Option deleted successfully", "success");
      fetchQuestions();
    } catch (error) {
      notify(formatErrorObject(error), "error");
    }
  };

  const openQuestionModal = (question?: Question) => {
    setIsQuestionModalOpen(true);
    setEditingQuestion(question || null);
    form.setFieldsValue(question || { text: "" });
  };

  const closeQuestionModal = () => {
    setIsQuestionModalOpen(false);
    setEditingQuestion(null);
    form.resetFields();
  };

  const openOptionModal = (questionId: string) => {
    setEditingOption({ question: questionId });
    setIsOptionModalOpen(true);
    optionForm.resetFields();
  };

  const closeOptionModal = () => {
    setIsOptionModalOpen(false);
    setEditingOption(null);
    optionForm.resetFields();
  };

  const handleQuestionModalSubmit = async () => {
    const values = await form.validateFields();
    await handleCreateOrUpdateQuestion(values);
    closeQuestionModal();
  };

  const handleOptionModalSubmit = async () => {
    const values = await optionForm.validateFields();
    if (editingOption?.question) {
      await handleCreateOption(editingOption.question, values);
    }
    closeOptionModal();
  };

  useEffect(() => {
    fetchQuestions();
  }, [categoryId]);

  const columns: ColumnsType<Question> = [
    {
      title: "Question",
      dataIndex: "text",
      key: "text",
    },
    {
      title: "Options",
      key: "options",
      render: (_, record) => (
        <ul>
          {record.options.map((opt) => (
            <li key={opt._id} className="flex gap-x-2 items-center flex-wrap">
              {opt.label} <p className="text-orange-400">({opt.value})</p>{" "}
              <Popconfirm
                title="Delete this option?"
                onConfirm={() => handleDeleteOption(opt._id)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="link" danger style={{ padding: 0 }}>
                  Delete option
                </Button>
              </Popconfirm>
            </li>
          ))}
        </ul>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space>
          <Button type="link" onClick={() => openOptionModal(record._id)}>
            Add Option
          </Button>
          <Popconfirm
            title="Delete this question?"
            onConfirm={() => handleDeleteQuestion(record._id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link" danger>
              Delete Question
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div className="bg-readerLightGrayBg rounded-xl ">
      <div className="py-4 pr-6 pl-3 flex flex-col justify-between md:flex-row gap-x-2 items-center">
        <p className="font-semibold items-center gap-x-2 flex justify-start text-start text-base lg:text-lg whitespace-nowrap">
          <Button
            onClick={() =>
              router(`${type ? "/onboarding-questions" : "/personality-type"}`)
            }
            icon={<ChevronLeftIcon className="w-3 " />}
            type="text"
          >
            Back
          </Button>{" "}
          Questions{" "}
        </p>
        <span>
          <Button onClick={() => openQuestionModal()} icon={<PlusOutlined />}>
            Add Question
          </Button>
        </span>
      </div>
      <div className="customScrollBar ">
        <Table
          dataSource={questions}
          columns={columns}
          rowKey="_id"
          loading={loading}
          pagination={false}
          locale={{
            emptyText: <Empty description="No questions available" />,
          }}
        />
      </div>

      <Modal
        title={editingQuestion ? "Edit Question" : "Add Question"}
        open={isQuestionModalOpen}
        onCancel={closeQuestionModal}
        onOk={handleQuestionModalSubmit}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            label="Question Text"
            name="text"
            rules={[
              { required: true, message: "Please enter the question text" },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title={editingOption?._id ? "Edit Option" : "Add Option"}
        open={isOptionModalOpen}
        onCancel={closeOptionModal}
        onOk={handleOptionModalSubmit}
      >
        <Form form={optionForm} layout="vertical">
          <Form.Item
            label="Label"
            name="label"
            rules={[
              { required: true, message: "Please enter the option label" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Value"
            name="value"
            rules={[{ required: true, message: "Please select a value" }]}
          >
            <Select>
              {valueTypes?.split(",")?.map((value) => (
                <Select.Option key={value} value={value}>
                  {value}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default QuestionTable;
