import React, { useState, useEffect } from "react";
import { Table, Input, Pagination, Select, Button } from "antd";
import type { ColumnsType } from "antd/es/table";
import { SearchOutlined } from "@ant-design/icons";
import { getUserSubmissions } from "../../../services/personalityTest";
import { notify } from "../../shared/basic/notify";
import { formatErrorObject } from "../../../utils/lib/auth";
import moment from "moment";
import { DatePicker } from "antd";
import { useNavigate } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/outline";
const { RangePicker } = DatePicker;

interface Answer {
  questionId: string;
  optionId: string;
  _id: string;
}

interface Category {
  _id: string;
  name: string;
  valueTypes: string[];
}

interface Submission {
  _id: string;
  tempUserId: string;
  zl_uid: string | null;
  email: string | null;
  combination: string;
  answers: Answer[];
  category: Category;
  createdAt: string;
  updatedAt: string;
}

interface UserSubmission {
  user: string;
  submissions: Submission[];
}

interface PaginationInfo {
  page: number;
  limit: number;
  total: number;
  totalPages: number;
}

const UserSubmissionTable: React.FC = () => {
  const [data, setData] = useState<UserSubmission[]>([]);
  const [pagination, setPagination] = useState<PaginationInfo>({
    page: 1,
    limit: 10,
    total: 0,
    totalPages: 1,
  });
  const [loading, setLoading] = useState(false);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [search, setSearch] = useState<string>("");
  const [dateRange, setDateRange] = useState<
    { start: string; end: string } | undefined
  >(undefined);
  const router = useNavigate();

  const fetchData = async (params: {
    page?: number;
    limit?: number;
    sort?: "asc" | "desc";
    search?: string;
    dateRange?: { start: string; end: string };
  }) => {
    try {
      setLoading(true);
      const submissions = await getUserSubmissions(params);
      setData(submissions.data.results);
      setPagination(submissions.data.pagination);
    } catch (error) {
      notify(formatErrorObject(error), "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData({
      page: pagination.page,
      limit: pagination.limit,
      sort: sortOrder,
      search,
      dateRange,
    });
  }, [pagination.page, pagination.limit, sortOrder, search, dateRange]);

  const onSearch = (value: string) => {
    setSearch(value);
    setPagination((prev) => ({ ...prev, page: 1 }));
  };

  const onTableChange = (page: number, pageSize: number) => {
    setPagination({ ...pagination, page, limit: pageSize });
  };

  const onSortChange = (value: "asc" | "desc") => {
    setSortOrder(value);
  };

  const nestedColumns: ColumnsType<Submission> = [
    { title: "ZL UID", dataIndex: "zl_uid", key: "zl_uid" },
    { title: "Email", dataIndex: "email", key: "email" },
    { title: "Combination", dataIndex: "combination", key: "combination" },
    { title: "Category", dataIndex: ["category", "name"], key: "category" },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) =>
        createdAt ? moment(createdAt).format("Do MMM YYYY") : "N/A",
    },
  ];

  const userColumns: ColumnsType<UserSubmission> = [
    {
      title: "ZL UID",
      dataIndex: "submissions",
      key: "zl_uid",
      render: (submissions) =>
        submissions.length > 0 ? submissions[0].zl_uid : "N/A",
    },
    {
      title: "Email",
      dataIndex: "submissions",
      key: "email",
      render: (submissions) =>
        submissions.length > 0 ? submissions[0].email : "N/A",
    },
    {
      title: "Temp User ID",
      dataIndex: "submissions",
      key: "tempUserId",
      render: (submissions) =>
        submissions.length > 0 ? submissions[0].tempUserId : "N/A",
    },
    {
      title: "Combination",
      dataIndex: "submissions",
      key: "combination",
      render: (submissions) => {
        if (submissions.length === 0) return "N/A";
        const combinationLetters = submissions[
          submissions.length - 1
        ].combination
          .split("-")
          .map((item: string) => item[0])
          .join("");

        return `[${combinationLetters}]`;
      },
    },
    {
      title: "Created At",
      dataIndex: "submissions",
      key: "createdAt",
      render: (submissions) =>
        submissions.length > 0
          ? moment(submissions[0].createdAt).format("Do MMM YYYY")
          : "N/A",
    },
  ];

  return (
    <div className="bg-readerLightGrayBg rounded-xl pb-5 ">
      <div className="py-4 pr-6 pl-3 flex flex-col justify-between md:flex-row gap-x-2 items-center">
        <p className="font-semibold items-center gap-x-2 flex justify-start text-start text-base lg:text-lg whitespace-nowrap">
          <Button
            onClick={() => router("/personality-type")}
            icon={<ChevronLeftIcon className="w-3 " />}
            type="text"
          >
            Back
          </Button>{" "}
          User Submissions{" "}
        </p>
        <span className="flex gap-x-4">
          <Input.Search
            placeholder="Search by ZL UID or Email"
            onSearch={onSearch}
            style={{ marginLeft: 8, width: 250 }}
            enterButton={
              <div>
                <SearchOutlined />
              </div>
            }
          />
          <Select
            value={sortOrder}
            onChange={onSortChange}
            options={[
              { value: "asc", label: "Ascending" },
              { value: "desc", label: "Descending" },
            ]}
            style={{ width: 150 }}
          />
          <RangePicker
            onChange={(dates, dateStrings) => {
              if (dates) {
                setDateRange({
                  start: dateStrings[0],
                  end: dateStrings[1],
                });
              } else {
                setDateRange(undefined);
              }
              setPagination((prev) => ({ ...prev, page: 1 }));
            }}
            style={{ marginLeft: 8, width: 250 }}
            placeholder={["Start Date", "End Date"]}
          />
        </span>
      </div>
      <div className="customScrollBar">
        <Table<UserSubmission>
          rowKey="user"
          dataSource={data}
          columns={userColumns}
          loading={loading}
          pagination={false}
          expandable={{
            expandedRowRender: (record) => (
              <Table<Submission>
                rowKey="_id"
                dataSource={record.submissions}
                columns={nestedColumns}
                pagination={false}
                size="small"
              />
            ),
          }}
        />
        <Pagination
          current={pagination.page}
          pageSize={pagination.limit}
          total={pagination.total}
          onChange={onTableChange}
          style={{ marginTop: 16, textAlign: "right" }}
        />
      </div>
    </div>
  );
};

export default UserSubmissionTable;
