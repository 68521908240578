import {
  Category,
  CategoryType,
} from "../../components/PersonalityTypeComp/CategoryTable";
import {
  Option,
  Question,
} from "../../components/PersonalityTypeComp/QuestionTable";
import { IResults } from "../../components/PersonalityTypeComp/ResultsPage";
import { Endpoints } from "../../network";
import apiClient from "../base";

export const getCategoriesAPI = async (type?: CategoryType): Promise<any> => {
  const queryParams = new URLSearchParams({
    ...(type && { type }),
  });
  const response = await apiClient.get(
    `${Endpoints.getAllCategory}?${queryParams}`
  );
  return response?.data;
};

export const createCategoryAPI = async (
  data: Partial<Category>,
  type?: CategoryType
): Promise<any> => {
  const payload = {
    ...data,
    ...(type && { type }),
  };
  const response = await apiClient.post(`${Endpoints.getAllCategory}`, payload);
  return response?.data;
};

export const updateCategoryAPI = async (
  id: string,
  data: Partial<Category>
): Promise<any> => {
  const response = await apiClient.put(
    `${Endpoints.getAllCategory}/${id}`,
    data
  );
  return response?.data;
};

export const deleteCategoryAPI = async (id: string): Promise<any> => {
  const response = await apiClient.delete(`${Endpoints.getAllCategory}/${id}`);
  return response?.data;
};

export const createQuestionAPI = async (
  data: Partial<Question>
): Promise<any> => {
  const response = await apiClient.post(`${Endpoints.questions}`, data);
  return response?.data;
};

export const getQuestionsAPI = async (categoryId: string): Promise<any> => {
  const response = await apiClient.get(`${Endpoints.questions}/${categoryId}`);
  return response?.data;
};

export const getUserSubmissions = async (params: {
  page?: number;
  limit?: number;
  sort?: "asc" | "desc";
  search?: string;
}): Promise<any> => {
  const response = await apiClient.get(Endpoints.getUserSubmission, { params });
  return response?.data;
};

export const getOnboardingUserSubmissions = async (params: {
  page?: number;
  limit?: number;
  sort?: "asc" | "desc";
  search?: string;
}): Promise<any> => {
  const response = await apiClient.get(Endpoints.onboardingSubmission, {
    params,
  });
  return response?.data;
};

export const updateQuestionAPI = async (
  id: string,
  data: Partial<Question>
): Promise<any> => {
  const response = await apiClient.put(`${Endpoints.questions}/${id}`, data);
  return response?.data;
};

export const deleteQuestionAPI = async (id: string): Promise<any> => {
  const response = await apiClient.delete(`${Endpoints.questions}/${id}`);
  return response?.data;
};

export const createOptionAPI = async (data: Partial<Option>): Promise<any> => {
  const response = await apiClient.post(`${Endpoints.options}`, data);
  return response?.data;
};

export const deleteOptionAPI = async (id: string): Promise<any> => {
  const response = await apiClient.delete(`${Endpoints.deleteOption}/${id}`);
  return response?.data;
};

export const getResultsAPI = async (): Promise<any> => {
  const response = await apiClient.get(`${Endpoints.resultsPage}?limit=999`);
  return response?.data;
};

export const createResultsAPI = async (
  data: Partial<IResults>
): Promise<any> => {
  const response = await apiClient.post(`${Endpoints.resultsPage}`, data);
  return response?.data;
};

export const updateResultsAPI = async (
  id: string,
  data: Partial<IResults>
): Promise<any> => {
  const response = await apiClient.put(`${Endpoints.resultsPage}/${id}`, data);
  return response?.data;
};

export const deleteResultsAPI = async (id: string): Promise<any> => {
  const response = await apiClient.delete(`${Endpoints.resultsPage}/${id}`);
  return response?.data;
};
